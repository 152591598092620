// Imports
import React from 'react';
import { useAppDispatch } from '../../../front-end/redux/hooks';
import styled from 'styled-components/macro';
import helperFunctions from '../../../../../shareables/foundation/front-end/utils/helper-functions';
import adminUIActions from '../../../../../shareables/foundation/admin-ui/redux/actions';


// Styled components
const inFocusTopOffset = '20px';

interface ButtonProps {
	focused?: boolean;
}

const Button = styled.button<ButtonProps>`
	padding: 10px 20px;
	background: #fff;
	border: 0;
	position: fixed;
	top: ${(props) => (props.focused ? inFocusTopOffset : '-9999px')};
	left: 20px;
	z-index: 9999;
	color: #000;
	font-size: 1rem;
	line-height: 1;
	text-decoration: underline;
	
	&:focus {
		top: ${inFocusTopOffset};
	}
`;


// Define the accepted props
interface Props {
	focused?: boolean;
}


// Function component
const SkipToMainContent: React.FC<Props> = ({ focused }) => {
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Return JSX
	return (
		<Button
			focused={focused}
			className='hide-until-focused'
			{...helperFunctions.buttonize(() => dispatch(adminUIActions.hideSidebar()))}
		>
			&larr; Hide sidebar
		</Button>
	);
};

export default SkipToMainContent;
