// Imports
import React, { lazy } from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import NotFound from './shareables/foundation/admin-ui/components/pages/not-found';
import CannySSO from './components/pages/canny-sso';
import ContentErrorBoundary from './shareables/foundation/admin-ui/components/resources/content-error-boundary';
import useCachedState from './shareables/foundation/front-end/utils/use-cached-state';
import { GetWebsitesResponse } from './shareables/types/api/main/self/websites/get';
import { useAppSelector } from './shareables/foundation/front-end/redux/hooks';


// Lazily-loaded sections
const Dashboard = lazy(() => import('./components/sections/dashboard'));
const Pages = lazy(() => import('./components/sections/pages/section-routes'));
const Members = lazy(() => import('./components/sections/members/section-routes'));
const Groups = lazy(() => import('./components/sections/groups/section-routes'));
const EmailAccounts = lazy(() => import('./components/sections/email-accounts/section-routes'));
const Surveys = lazy(() => import('./components/sections/surveys/section-routes'));
const ActivityLog = lazy(() => import('./components/sections/activity-log'));
const VisitorTracking = lazy(() => import('./components/sections/visitor-tracking'));
const Settings = lazy(() => import('./components/sections/settings/section-routes'));
const Communications = lazy(() => import('./components/sections/communications/section-routes'));
const OnlinePayments = lazy(() => import('./components/sections/online-payments/section-routes'));


// Other lazily-loaded components
const SelectWebsite = lazy(() => import('./components/pages/select-website'));
const WebsiteReview = lazy(() => import('./components/sections/website-review'));


// Function component
const ProtectedRoutes: React.FC = () => {
	// Use Redux functionality
	const signedIn = useAppSelector((state) => state.common.user.signedIn);
	const [website] = useCachedState<GetWebsitesResponse[0] | null>(null, 'website');
	
	
	// Handle special cases
	if (signedIn && !website) {
		return <SelectWebsite />;
	}
	
	if (!signedIn) {
		return null;
	}
	
	
	// Return JSX
	return <Outlet />;
};


// Routes
const appRoutes = (
	<Route
		element={
			<ContentErrorBoundary>
				<Outlet />
			</ContentErrorBoundary>
		}
	>
		<Route element={<ProtectedRoutes />}>
			<Route index element={<Navigate to='/dashboard' replace />} />
			<Route path='/dashboard' element={<Dashboard />} />
			<Route path='/pages/*' element={<Pages />} />
			<Route path='/members/*' element={<Members />} />
			<Route path='/groups/*' element={<Groups />} />
			<Route path='/activity-log' element={<ActivityLog />} />
			<Route path='/email-accounts/*' element={<EmailAccounts />} />
			<Route path='/surveys/*' element={<Surveys />} />
			<Route path='/settings/*' element={<Settings />} />
			<Route
				path='/website-review'
				element={
					<ContentErrorBoundary>
						<WebsiteReview />
					</ContentErrorBoundary>
				}
			/>
			<Route path='/visitor-tracking' element={<VisitorTracking />} />
			<Route path='/communications/*' element={<Communications />} />
			<Route path='/online-payments/*' element={<OnlinePayments />} />
		</Route>
		<Route path='canny' element={<CannySSO />} />
		<Route path='*' element={<NotFound />} />
	</Route>
);

export default appRoutes;
