// Imports
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../front-end/redux/hooks';
import adminUIActions from '../../../../../shareables/foundation/admin-ui/redux/actions';
import SidebarLinks from '../../../../../sidebar-links';
import styled, { css } from 'styled-components/macro';
import { setLightness, setSaturation } from 'polished';
import theme from '../../../../../shareables/theme';
import SidebarHeader from './header';
import SidebarFooter from './footer';
import Divider from './divider';
import useUpdateOnlyEffect from '../../../../../shareables/foundation/front-end/utils/use-update-only-effect';


// Styled components
interface ContainerProps {
	visible: boolean;
}

export const sidebarWidth = '15.625rem';

const Container = styled.div<ContainerProps>`
	width: ${sidebarWidth};
	min-height: 100%;
	background: ${setSaturation(0.25, setLightness(0.25, theme.colors.primary))};
	line-height: 1;
	display: flex;
	flex-direction: column;
	z-index: 10;
	position: fixed;
	
	
	// Scrolling small screens
	height: 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	
	ul {
		margin: 0;
		padding: 0;
	}
	
	
	// Responsive styling
	@media (max-width: ${theme.breakpoints.mobile}px) {
		max-width: 66%;
		box-shadow: 10px 0 20px rgba(0, 0, 0, 0.2);
		transform: translateX(-270px);
		-webkit-transform: translateX(-270px);
		transition:
			visibility 0s linear 0.4s,
			transform 0.4s ease;
		-webkit-transition:
			visibility 0s linear 0.4s,
			transform 0.4s ease;
		visibility: hidden;
		will-change: transform;
		
		${(props) =>
			props.visible
				? css`
						transform: none;
						visibility: visible;
						transition-delay: 0s;
				  `
				: ''}
	}
`;

interface FadeProps {
	visible: boolean;
}

const Fade = styled.div<FadeProps>`
	width: 100vw;
	height: 100vh;
	background: #fff;
	opacity: 0;
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	transition: all 0.3s ease;
	z-index: 9;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	will-change: opacity;
	
	${(props) =>
		props.visible
			? css`
					opacity: 0.4;
					visibility: visible;
			  `
			: ''}
`;

const Nav = styled.nav`
	flex-grow: 1;
`;


// Define the accepted props
interface Props {
	name: string;
	tagline: string | React.ReactChild;
	isBeta?: true;
	cannyAppID?: string;
	statuspageAppID?: string;
	utmSource?: string;
	storybookProductionDomain?: string;
}


// Function component
const Sidebar: React.FC<Props> = ({
	name,
	isBeta,
	tagline,
	cannyAppID,
	statuspageAppID,
	utmSource,
	storybookProductionDomain,
}) => {
	// Use Redux functionality
	const dispatch = useAppDispatch();
	const sidebarIsVisible = useAppSelector((state) => state.adminUI.sidebar.isVisible);
	
	
	// Hide sidebar
	const hideSidebar = () => {
		dispatch(adminUIActions.hideSidebar());
	};
	
	
	// Ensure tabbing will start in the desired place when sidebar is toggled
	useUpdateOnlyEffect(() => {
		const header = document.getElementById('header');
		const mainContent = document.getElementById('main-content');
		
		if (sidebarIsVisible) {
			header && header.focus();
		} else {
			mainContent && mainContent.focus();
		}
	}, [sidebarIsVisible]);
	
	
	// Return JSX
	return (
		<React.Fragment>
			<Container visible={sidebarIsVisible} aria-hidden={!sidebarIsVisible && window.innerWidth <= 768}>
				<SidebarHeader name={name} isBeta={isBeta} tagline={tagline} />
				
				<Divider />
				
				<Nav>
					<ul>
						<SidebarLinks />
					</ul>
				</Nav>
				
				<SidebarFooter
					name={name}
					cannyAppID={cannyAppID}
					statuspageAppID={statuspageAppID}
					utmSource={utmSource}
					storybookProductionDomain={storybookProductionDomain}
				/>
			</Container>
			
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
			<Fade visible={sidebarIsVisible} onClick={hideSidebar} />
		</React.Fragment>
	);
};

export default Sidebar;
