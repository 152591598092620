// Imports
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { setLightness, setSaturation } from 'polished';
import theme from '../../../../../shareables/theme';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faGridHorizontal } from '@fortawesome/pro-solid-svg-icons';
import customBreadcrumbs from '../../../../../custom-breadcrumbs';


// Define what custom breadcrumbs look like
export type CustomBreadcrumbs = {
	/** Criteria to match breadcrumbs to replace with the custom breadcrumb. When provided as a function matches against the entire URI, otherwise matches against a piece of it. */
	match: string | RegExp | ((uriForBreadcrumb: string, fullURI: string) => boolean);
	
	
	/** Value to replace a matched breadcrumb with. When provided as a function returns the URI the breadcrumb should link to, and the descriptor that users will see, otherwise returns the string to display to users or removes the breadcrumb entirely. */
	replacement:
		| string
		| undefined
		| ((uriForBreadcrumb: string, fullURI: string) => { descriptor: string; uri: string } | undefined);
}[];


// Styled components
const Container = styled.nav`
	margin-right: 2rem;
	flex-grow: 1;
	white-space: nowrap;
	overflow: hidden;
	position: relative;
	
	@media (max-width: ${theme.breakpoints.mobile}px) {
		display: none;
		visibility: hidden;
	}
`;

const Shadow = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	box-shadow: inset -30px 0px 15px -20px ${setSaturation(0.25, setLightness(0.32, theme.colors.primary))};
	pointer-events: none;
`;

const UL = styled.ul`
	margin: 0;
	padding: 0;
	
	@media (max-width: 900px) {
		display: none;
		visibility: hidden;
	}
`;

const LI = styled.li`
	font-size: 0.875rem;
	line-height: 2;
	list-style: none;
	display: inline;
	
	> svg {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
		top: -0.05rem;
		position: relative;
		font-size: 0.625rem;
		color: #7a8c99;
	}
	
	a {
		color: #b8d2e6;
		transition: all 0.2s ease;
		text-decoration: none;
		
		&:hover {
			color: #fff;
		}
		
		svg {
			color: #6b8599;
		}
	}
	
	> span {
		color: #fff;
		cursor: default;
	}
`;


// Define the accepted props
interface Props {
	fullURI: string;
}


// Function component
const Breadcrumbs: React.FC<Props> = ({ fullURI }) => {
	// Filter URI pieces to those that will become breadcrumbs
	let renderableBreadcrumbs = 0;
	const missingURIPieces: Record<number, string> = {};
	let uriForBreadcrumb = '';
	
	const breadcrumbPieces = fullURI.split('/').filter((piece) => {
		// Add the current URI piece to the previous pieces, this will be used for filtering custom breadcrumbs
		uriForBreadcrumb += `/${piece}`;
		
		
		// Filter values we never create a breadcrumb for
		const decodedURIPiece = decodeURIComponent(piece);
		
		if (
			!isNaN(parseInt(piece)) ||
			decodedURIPiece.includes('https://') ||
			decodedURIPiece.includes('@') ||
			decodedURIPiece.includes('.')
		) {
			missingURIPieces[renderableBreadcrumbs] = `${missingURIPieces[renderableBreadcrumbs] ?? ''}/${piece}`;
			
			return false;
		}
		
		
		// Check for matching custom breadcrumbs
		const customBreadCrumbMatch = customBreadcrumbs.find(({ match }) => {
			if (typeof match === 'string') {
				return match === piece;
			}
			
			if (typeof match === 'function') {
				return match(uriForBreadcrumb, fullURI);
			}
			
			return match.test(piece);
		});
		
		
		// Filter out breadcrumbs we don't want to display
		let isRenderable: boolean;
		
		if (customBreadCrumbMatch) {
			if (typeof customBreadCrumbMatch.replacement === 'function') {
				isRenderable = Boolean(customBreadCrumbMatch.replacement(uriForBreadcrumb, fullURI));
			} else {
				isRenderable = Boolean(customBreadCrumbMatch.replacement);
			}
			
			if (!isRenderable) {
				missingURIPieces[renderableBreadcrumbs] = `${missingURIPieces[renderableBreadcrumbs] ?? ''}/${piece}`;
				
				return false;
			}
		}
		
		
		// If this is a renderable breadcrumb we need to track that
		isRenderable = Boolean(piece);
		
		if (isRenderable) {
			renderableBreadcrumbs += 1;
		}
		
		
		// Filter empty strings
		return isRenderable;
	});
	
	
	// Helper function that adds a piece to the page title
	let title = '';
	
	const addTitlePiece = (piece: string) => {
		title = `${piece}${title.length ? ' // ' : ''}${title}`;
	};
	
	
	// Build breadcrumbs/title
	uriForBreadcrumb = '';
	let finalPiece: string | undefined = '';
	
	const breadcrumbs = breadcrumbPieces.map((piece, index) => {
		// Add the current URI piece to the previous pieces, this will be where the breadcrumb links to by default
		uriForBreadcrumb += `${missingURIPieces[index] ?? ''}/${piece}`;
		
		
		// Check for matching custom breadcrumbs
		const customBreadCrumbMatch = customBreadcrumbs.find(({ match }) => {
			if (typeof match === 'string') {
				return match === piece;
			}
			
			if (typeof match === 'function') {
				return match(uriForBreadcrumb, fullURI);
			}
			
			return match.test(piece);
		});
		
		
		// If the custom breadcrumb replacement is a function, create a breadcrumb from the result
		if (typeof customBreadCrumbMatch?.replacement === 'function') {
			// Get the replacement breadcrumb
			const breadcrumb = customBreadCrumbMatch.replacement(uriForBreadcrumb, fullURI);
			
			
			// Render nothing if the result is falsy
			if (!breadcrumb) {
				return null;
			}
			
			
			// Add the descriptor to the title
			addTitlePiece(breadcrumb.descriptor);
			
			
			// Return JSX
			return (
				<LI key={breadcrumb.descriptor}>
					{index !== 0 && <FontAwesomeIcon icon={faChevronRight} />}
					
					{index === breadcrumbPieces.length - 1 ? (
						<span>{breadcrumb.descriptor}</span>
					) : (
						<NavLink to={breadcrumb.uri} end>
							{breadcrumb.descriptor}
						</NavLink>
					)}
				</LI>
			);
		}
		
		
		// Use the custom breadcrumb replacement if one matched, otherwise fallback to default formatting based on the URI piece
		finalPiece = customBreadCrumbMatch
			? customBreadCrumbMatch.replacement
			: piece.charAt(0).toUpperCase() + piece.slice(1).replace(/-/g, ' ');
		
		
		// Render nothing if the result is falsy
		if (!finalPiece) {
			return null;
		}
		
		
		// Add the result to the title
		addTitlePiece(finalPiece);
		
		
		// Return JSX
		return (
			<LI key={piece}>
				{index !== 0 && <FontAwesomeIcon icon={faChevronRight} />}
				
				{index === breadcrumbPieces.length - 1 ? (
					<span>{finalPiece}</span>
				) : (
					<NavLink to={uriForBreadcrumb} end>
						{finalPiece}
					</NavLink>
				)}
			</LI>
		);
	});
	
	
	// Return JSX
	return (
		<Container className='breadcrumbs is-hidden-mobile'>
			<Helmet title={title} />
			<Shadow />
			
			<UL>
				<LI>
					<NavLink to='/' aria-label='Dashboard'>
						<FontAwesomeIcon icon={faGridHorizontal} />
					</NavLink>
					
					{breadcrumbs.length > 0 && <FontAwesomeIcon icon={faChevronRight} />}
				</LI>
				
				{breadcrumbs}
			</UL>
		</Container>
	);
};

export default Breadcrumbs;
