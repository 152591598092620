import { CustomBreadcrumbs } from './shareables/foundation/admin-ui/components/top-bar/breadcrumbs';

const customBreadcrumbs: CustomBreadcrumbs = [
	{
		match: 'custom-css',
		replacement: 'Custom CSS',
	},
	{
		match: /^(sms|email|letter)$/,
		replacement: undefined,
	},
	{
		match: 'whats-happening',
		replacement: 'What’s Happening',
	},
	{
		match: 'google-analytics',
		replacement: 'Google Analytics',
	},
	{
		match: /^person_[a-zA-Z0-9]+$/,
		replacement: undefined,
	},
	{
		match: 'transaction',
		replacement: undefined,
	},
	{
		match: (uriForBreadcrumb, fullURI) => {
			return /\/pages\/[a-z-]+\/[0-9]+\/.*/.test(fullURI) && /\/pages\/[a-z-]+$/.test(uriForBreadcrumb);
		},
		replacement: (uriForBreadcrumb, fullURI) => {
			let pathIncludingPageID = `${uriForBreadcrumb}/`;
			
			for (let i = pathIncludingPageID.length; i < fullURI.length; i += 1) {
				pathIncludingPageID += fullURI[i];
				
				if (fullURI[i] === '/') {
					break;
				}
			}
			
			pathIncludingPageID = `${pathIncludingPageID}view`;
			
			return {
				uri: pathIncludingPageID,
				descriptor: 'View',
			};
		},
	},
	{
		match: (uriForBreadcrumb) => /\/pages\/([a-z-]+)\/[0-9]+\/view$/.test(uriForBreadcrumb),
		replacement: undefined,
	},
	{
		match: 'add-faq',
		replacement: 'Add FAQ',
	},
	{
		match: 'edit-faq',
		replacement: 'Edit FAQ',
	},
];

export default customBreadcrumbs;
