// Imports
import React, { useEffect } from 'react';
import { useAppSelector } from '../../../front-end/redux/hooks';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import styled, { css } from 'styled-components/macro';
import { setLightness, setSaturation } from 'polished';
import theme from '../../../../../shareables/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faLock } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import uniq from 'lodash/uniq';
import { Link } from 'react-router-dom';
import UserAvatar from '../../../front-end/components/resources/user-avatar';


// Styled components
const Button = styled.button`
	width: 1.75rem;
	height: 1.75rem;
	background: none;
	padding: 0;
	border: none;
	margin-left: 0.75rem;
`;

const baseProfileCSS = css`
	width: 1.75rem;
	height: 1.75rem;
	
	border: 0.0625rem solid ${setSaturation(0.18, setLightness(0.51, theme.colors.primary))};
	
	cursor: pointer;
`;

const ProfilePhoto = styled.img`
	background: #fff;
	border-radius: 999rem;
	
	${baseProfileCSS};
`;

const ProfileAvatar = styled(UserAvatar)`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	
	font-size: 0.75rem;
	
	${baseProfileCSS};
`;

interface DropdownProps {
	visible: boolean;
}

const Dropdown = styled.div<DropdownProps>`
	padding: 0.6rem;
	padding-top: 0.5rem;
	border-radius: 4px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
	background: #fff;
	position: absolute;
	top: 3.3rem;
	right: 1.5rem;
	opacity: 0;
	visibility: hidden;
	transition:
		transform 0.2s ease,
		opacity 0.2s ease,
		visibility 0s linear 0.2s;
	will-change: transform;
	display: block;
	z-index: 99;
	
	${(props) =>
		props.visible
			? css`
					opacity: 1;
					transform: translateY(-0.3rem);
					transition:
						transform 0.2s ease,
						opacity 0.2s ease,
						visibility 0s linear 0s;
					visibility: visible;
			  `
			: ''}
	
	ul {
		margin: 0;
		padding: 0;
		
		> a {
			display: block;
			cursor: pointer;
			font-size: 0.875rem;
			color: #3d464d;
			
			&:not(:last-child) {
				margin-bottom: 0.5rem;
			}
			
			svg {
				margin-right: 0.33rem;
				font-size: 0.75rem;
				color: #9db38f;
			}
		}
	}
`;

const Greeting = styled.span`
	font-weight: 500;
	padding-bottom: 0.25rem;
	font-size: 0.9rem;
	display: block;
`;

const Email = styled.span`
	font-size: 0.75rem;
	color: #7a7a7a;
	display: block;
`;

const Divider = styled.hr`
	height: 2px;
	margin: 0.75rem 0;
	background-color: #f5f5f5;
	border: none;
	display: block;
`;


// Define what an extra item looks like
export interface ExtraUserDropdownItem {
	type: 'link' | 'anchor';
	to: string;
	icon: IconDefinition;
	content: string | React.ReactElement;
	uniqueKey: string;
}


// Define the accepted props
interface Props {
	signOut: (event?: React.MouseEvent | React.KeyboardEvent) => void;
	extraUserDropdownItems?: ExtraUserDropdownItem[];
	storybookVisible?: true;
}


// Function component
const UserDropdown: React.FC<Props> = ({ signOut, storybookVisible, extraUserDropdownItems }) => {
	// Use Redux functionality
	const signedIn = useAppSelector((state) => state.common.user.signedIn);
	const userFirstName = useAppSelector((state) => state.common.user.firstName);
	const userLastName = useAppSelector((state) => state.common.user.lastName);
	
	const userEmail = useAppSelector((state) => state.common.user.email);
	const profilePhotoURL = useAppSelector((state) => state.common.user.profilePhotoURL);
	
	const lastRouteChange = useAppSelector((state) => state.common.route.lastChange);
	
	
	// Use accessible dropdown
	const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu((extraUserDropdownItems?.length ?? 0) + 1);
	
	
	// Close user drop down when route changes
	useEffect(() => {
		setIsOpen(false);
	}, [lastRouteChange, setIsOpen]);
	
	
	// Determine SSO port (if any)
	let port = '';
	
	if (window.location.port) {
		port = ':3030';
	}
	
	
	// Initialize
	const dropdownItems: React.ReactChild[] = [];
	let itemIndex = 0;
	
	
	// Handle extra user dropdown items, if provided
	if (extraUserDropdownItems?.length) {
		// Prevent some problematic usages
		if (extraUserDropdownItems.some((item) => item.icon.prefix !== 'far' && item.icon.prefix !== 'fab')) {
			throw new Error('An extra user dropdown item’s icon should come from the "regular" or "brand" package');
		}
		
		const allUniqueKeys = ['sign-in', 'sign-out'].concat(extraUserDropdownItems.map((item) => item.uniqueKey));
		const actuallyUniqueKeys = uniq(allUniqueKeys);
		
		if (allUniqueKeys.length !== actuallyUniqueKeys.length) {
			throw new Error('An extra user dropdown item’s unique key must be unique');
		}
		
		
		// Iterate over items
		for (const item of extraUserDropdownItems ?? []) {
			// Push based on type
			if (item.type === 'anchor') {
				dropdownItems.push(
					<a {...itemProps[itemIndex]} key={item.uniqueKey} href={item.to}>
						<FontAwesomeIcon icon={item.icon} fixedWidth />
						{item.content}
					</a>
				);
			} else if (item.type === 'link') {
				dropdownItems.push(
					<Link {...itemProps[itemIndex]} key={item.uniqueKey} to={item.to}>
						<React.Fragment>
							<FontAwesomeIcon icon={item.icon} fixedWidth />
							{item.content}
						</React.Fragment>
					</Link>
				);
			} else {
				throw new Error('Unexpected item type');
			}
			
			
			// Increment index
			itemIndex++;
		}
	}
	
	
	// Add sign
	if (signedIn) {
		dropdownItems.push(
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
			<a
				{...itemProps[itemIndex]}
				key='sign-out'
				onClick={(event) => {
					signOut(event);
					setIsOpen(false);
				}}
			>
				<FontAwesomeIcon icon={faArrowRightFromBracket} fixedWidth />
				Sign out
			</a>
		);
	} else {
		dropdownItems.push(
			<a
				{...itemProps[itemIndex]}
				key='sign-in'
				href={`https://sso.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}${port}/?return=${encodeURIComponent(
					window.location.href
				)}`}
			>
				<FontAwesomeIcon icon={faLock} fixedWidth />
				Sign in
			</a>
		);
	}
	
	
	// Return JSX
	return (
		<React.Fragment>
			<Button {...buttonProps} aria-label='User dropdown menu'>
				{profilePhotoURL ? (
					<ProfilePhoto src={profilePhotoURL} alt='Profile photo' />
				) : (
					<ProfileAvatar firstName={userFirstName ?? undefined} lastName={userLastName ?? undefined} round />
				)}
			</Button>
			
			<Dropdown visible={isOpen || Boolean(storybookVisible)} role='menu'>
				{signedIn && (
					<React.Fragment>
						<p>
							<Greeting>Welcome, {userFirstName}</Greeting>
							<Email>{userEmail}</Email>
						</p>
						
						<Divider />
					</React.Fragment>
				)}
				
				<ul>{dropdownItems}</ul>
			</Dropdown>
		</React.Fragment>
	);
};

export default UserDropdown;
