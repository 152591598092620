// Imports
import { useState, useEffect, Dispatch, SetStateAction, useRef, useCallback } from 'react';
import keyValueStorage from './key-value-storage';


/** A custom Hook that behaves just like `useState()`, except that it stores its data using the key-value storage system so that it can rehydrate itself automatically. You’ll need to provide a unique storage key as the second parameter, using kabob case (`example-name`). */
export default function useCachedState<S>(defaultValue: S, storageKey: string): [S, Dispatch<SetStateAction<S>>] {
	// Throw error storage key has wrong casing
	if (storageKey.match(/[^a-z0-9-]/) !== null) {
		throw new Error('Storage keys must use kebab casing');
	}
	
	
	// Attempt to recover state on mount
	const hasMounted = useRef(false);
	
	interface DataNotRecovered {
		value: null;
		found: false;
	}
	
	interface DataRecovered {
		value: S;
		found: true;
	}
	
	let recoveredData: DataNotRecovered | DataRecovered = {
		value: null,
		found: false,
	};
	
	if (hasMounted.current === false) {
		hasMounted.current = true;
		
		try {
			const cachedData = keyValueStorage.getItem(storageKey);
			
			if (typeof cachedData === 'string') {
				recoveredData = {
					value: JSON.parse(cachedData) as S,
					found: true,
				};
			}
		} catch (ignore) {
			// Do nothing
		}
	}
	
	
	// Keep track of component's mounted state
	const isMounted = useRef(true);
	
	useEffect(() => {
		isMounted.current = true;
		
		return () => {
			isMounted.current = false;
		};
	}, []);
	
	
	// Use state
	const [data, setData] = useState(
		recoveredData.found &&
			!(window as typeof window & { STORYBOOK?: true }).STORYBOOK &&
			(!process.env.JEST_WORKER_ID || (window as typeof window & { ALLOW_RECOVERY?: boolean }).ALLOW_RECOVERY)
			? recoveredData.value
			: defaultValue
	);
	
	
	// Remember previous data
	const previousStringifiedData = useRef<string>();
	
	
	// Listen for updates to this storage key
	//  -> Stringifying large amounts of data is slow, so doing it inside the asynchronous
	//     `useEffect()` Hook is much more performant than doing it outside of it
	useEffect(() => {
		// Stringify the data and remember
		previousStringifiedData.current = JSON.stringify(data);
		
		
		// Subscribe to changes
		const index = keyValueStorage.subscribe(storageKey, (newValue) => {
			// Skip if data hasn't changed
			if (newValue === previousStringifiedData.current) {
				return;
			}
			
			
			// Update the state
			setData(newValue !== undefined ? (JSON.parse(newValue || '""') as S) : defaultValue);
		});
		
		
		// Unsubscribe
		return () => {
			keyValueStorage.unsubscribe(storageKey, index);
		};
	}); // eslint-disable-line react-hooks/exhaustive-deps
	
	
	// Memoize a setter function
	const setter: Dispatch<SetStateAction<S>> = useCallback(
		(newData) => {
			// Stringify the data for later use
			const stringifiedNewData = JSON.stringify(newData);
			
			
			// Save to key-value storage
			if (isMounted.current) {
				previousStringifiedData.current = stringifiedNewData;
				keyValueStorage.setItem(storageKey, stringifiedNewData);
			}
			
			
			// Save to local state data
			setData(newData);
		},
		[storageKey]
	);
	
	
	// Return
	return [data, setter];
}
