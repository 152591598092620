// Imports
import React from 'react';
import Bowser from 'bowser';
import styled from 'styled-components/macro';
import theme from '../../../../theme';
import { getContrast, setLightness, setSaturation } from 'polished';


// Styled component
const Wrapper = styled.div`
	padding: 1rem 1.25rem;
	line-height: 1rem;
	
	color: ${getContrast(theme.colors.danger, '#fff') > 1.9
		? '#fff'
		: setSaturation(0.2, setLightness(0.3, theme.colors.danger))};
	
	background-color: ${theme.colors.danger};
	
	position: sticky;
	top: 0;
	left: 0;
	z-index: 10;
`;


// Define the accepted props
interface Props {
	minimumVersions?: {
		chrome?: number;
		edge?: number;
		firefox?: number;
		safari?: number;
	};
}


// Function component
const BrowserVersionWarningBanner: React.FC<Props> = ({ minimumVersions: minimumVersionsProp }) => {
	// Minimum required versions by shareable components within Spark
	const sparkMinimumVersions = {
		chrome: 80, // Supports `overflow-wrap: anywhere`
		edge: 80, // Supports `overflow-wrap: anywhere`
		firefox: 93, // Supports input attribute `type=datetime-local`
		safari: 15.4, // Supports `overflow-wrap: anywhere`
	};
	
	
	// Build minimum versions by comparing Spark requirements with prop requirements
	const minimumVersions = {
		chrome: Math.max(minimumVersionsProp?.chrome ?? 0, sparkMinimumVersions.chrome),
		edge: Math.max(minimumVersionsProp?.edge ?? 0, sparkMinimumVersions.edge),
		firefox: Math.max(minimumVersionsProp?.firefox ?? 0, sparkMinimumVersions.firefox),
		safari: Math.max(minimumVersionsProp?.safari ?? 0, sparkMinimumVersions.safari),
	};
	
	
	// Initialize parser
	const browser = Bowser.getParser(window.navigator.userAgent);
	
	
	// Check if browser satisfies version requirements
	const satisfiesMinimumVersionRequirements = browser.satisfies({
		chrome: `>=${minimumVersions.chrome}`,
		edge: `>=${minimumVersions.edge}`,
		firefox: `>=${minimumVersions.firefox}`,
		safari: `>=${minimumVersions.safari}`,
	});
	
	if (satisfiesMinimumVersionRequirements) {
		return null;
	}
	
	
	// Return JSX
	return (
		<Wrapper color='danger'>
			It looks like you’re using{' '}
			<strong>
				{browser.getBrowserName()} {browser.getBrowserVersion()}
			</strong>
			. We recommend upgrading to the latest version of {browser.getBrowserName()}, or you may experience unexpected
			behavior while using this site.
		</Wrapper>
	);
};

export default BrowserVersionWarningBanner;
