// Imports
import React from 'react';
import * as Sentry from '@sentry/browser';
import { Container, Wrapper } from '../../../../../shareables/foundation/admin-ui/components/content';
import Heading from '../../../../../shareables/foundation/admin-ui/components/content/heading';
import Box from '../../../../../shareables/foundation/admin-ui/components/content/box';


// Component class
//  -> There are no Hooks for error boundary functions yet (https://reactjs.org/docs/error-boundaries.html)
class GlobalErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
	constructor(props: { children: React.ReactNode }) {
		super(props);
		this.state = { hasError: false };
	}
	
	static getDerivedStateFromError() {
		return { hasError: true };
	}
	
	componentDidCatch(error: Error) {
		// Report to Sentry
		Sentry.captureException(error);
	}
	
	render() {
		if (this.state.hasError) {
			return (
				<Container>
					<Wrapper role='main' id='main-content'>
						<Heading size={1}>An unexpected issue occurred</Heading>
						
						<Box>
							The interface crashed for an unknown reason. This issue has been reported to our engineering team.
						</Box>
					</Wrapper>
				</Container>
			);
		}
		
		return this.props.children;
	}
}


// Export
export default GlobalErrorBoundary;
