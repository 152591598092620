// Imports
import { useAppDispatch, useAppSelector } from '../shareables/foundation/front-end/redux/hooks';
import useCachedState from '../shareables/foundation/front-end/utils/use-cached-state';
import { GetWebsitesParameters, GetWebsitesResponse } from '../shareables/types/api/main/self/websites/get';
import apiActions from '../shareables/integrations/api/redux/actions';
import keyValueStorage from '../shareables/foundation/front-end/utils/key-value-storage';
import { useCallback } from 'react';


/** React Hook that returns a function to update the list of cached websites and the currently selected website */
export default function useRefreshWebsites(websiteID?: GetWebsitesResponse[0]['id']) {
	// Use cached state
	const [website, setWebsite] = useCachedState<GetWebsitesResponse[0] | null>(null, 'website');
	const [, setWebsites] = useCachedState<GetWebsitesResponse | null>(null, 'websites');
	
	
	// Use Redux functionality
	const dispatch = useAppDispatch();
	const signedIn = useAppSelector((state) => state.common.user.signedIn);
	
	
	// Extract currently selected website ID
	const currentWebsiteID = website?.id;
	
	
	// Function that retrieves and sets websites
	const refreshWebsites = useCallback(() => {
		if (!signedIn) {
			return Promise.resolve();
		}
		
		return new Promise<void>((resolve) => {
			void dispatch(
				apiActions.call<GetWebsitesParameters, undefined, GetWebsitesResponse>({
					action: 'GET',
					uri: '/self/websites',
					parameters: {
						...(websiteID && {
							websiteID,
						}),
					},
					cancelOnRouteChange: false,
					completion: (json) => {
						// Normalize
						const websites: GetWebsitesResponse = [];
						
						for (const website of json.response) {
							if (website.privileges.length && website.startProcessComplete) {
								websites.push(website);
							}
						}
						
						
						// Store websites
						setWebsites(websites);
						
						
						// Clear all but the `websites` key from local storage if website isn't available
						if (currentWebsiteID && !websites.some((website) => website.id === currentWebsiteID)) {
							keyValueStorage.clearSelectively(['websites']);
							resolve();
							return;
						}
						
						
						// Auto-select if there's just one
						if (websites.length === 1) {
							setWebsite(websites[0]);
							resolve();
							return;
						}
						
						
						// Auto-select if we a website ID was requested
						if (websites.length > 1 && websiteID && websites.map((website) => website.id).includes(websiteID)) {
							setWebsite(websites.find((website) => website.id === websiteID) ?? null);
							resolve();
							return;
						}
						
						
						// Try to update cached website
						if (currentWebsiteID && websites.length > 1) {
							setWebsite(websites.find((website) => website.id === currentWebsiteID) ?? null);
							resolve();
							return;
						}
						
						
						// Resolve
						resolve();
					},
				})
			);
		});
	}, [dispatch, signedIn, websiteID, currentWebsiteID, setWebsites, setWebsite]);
	
	
	// Return
	return refreshWebsites;
}
