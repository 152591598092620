// Imports
import ReactDOM from 'react-dom/client';
import App from './shareables/foundation/admin-ui/components/app';
import Tagline from './components/sidebar/tagline';
import WebsiteFunctionality from './components/resources/website-functionality';
import setUp from './shareables/foundation/front-end/utils/set-up';
import { faMagnifyingGlass, faGears } from '@fortawesome/pro-regular-svg-icons';
import 'react-loading-skeleton/dist/skeleton.css';


// Set up
setUp();


// Disable Plausible if not in production
if (process.env.REACT_APP__ENVIRONMENT !== 'production') {
	localStorage.setItem('plausible_ignore', 'true');
}


// Create root
const container = document.getElementById('root');

if (!container) {
	throw new Error('Root container should exist');
}

const root = ReactDOM.createRoot(container);


// Mount & render
root.render(
	<App
		name='Admin Portal'
		tagline={<Tagline />}
		extraTopLevelComponents={[<WebsiteFunctionality key={0} />]}
		extraUserDropdownItems={[
			{
				type: 'link',
				to: '/website-review',
				content: 'Request a website review',
				icon: faMagnifyingGlass,
				uniqueKey: 'website-review',
			},
			{
				type: 'link',
				to: '/settings/',
				content: 'Settings',
				icon: faGears,
				uniqueKey: 'settings',
			},
		]}
	/>
);
