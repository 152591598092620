// Imports
import { StorePendingAccountsAction, STORE_PENDING_ACCOUNTS } from './types';
import { GetAccountsResponse } from '../../shareables/types/api/main/website/admin/accounts/get';


// Exports
const selfActions = {
	// Store pending accounts
	storePendingAccounts(accounts: GetAccountsResponse): StorePendingAccountsAction {
		return {
			type: STORE_PENDING_ACCOUNTS,
			accounts,
		};
	},
};

export default selfActions;
