// Imports
import { GetAccountsResponse } from '../../shareables/types/api/main/website/admin/accounts/get';


// Export initial state interface
export interface State {
	pendingAccounts: GetAccountsResponse;
}


// Define types/interfaces for actions
export const STORE_PENDING_ACCOUNTS = 'STORE_PENDING_ACCOUNTS';
export interface StorePendingAccountsAction {
	type: typeof STORE_PENDING_ACCOUNTS;
	accounts: GetAccountsResponse;
}


// Export union type
export type Actions = StorePendingAccountsAction;
