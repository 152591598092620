// Imports
import styled from 'styled-components/macro';


// Styled component
const Aside = styled.aside`
	width: 90%;
	margin: 0 auto 1rem;
	padding: 0.4rem 0.8rem;
	border: 0.1rem solid;
	border-radius: 0.2rem;
	color: #fff;
	font-size: 0.85rem;
	line-height: 1rem;
`;

export default Aside;
