// Imports
import React from 'react';
import styled, { ThemeProvider } from 'styled-components/macro';
import { transparentize, darken } from 'polished';
import theme from '../../../../../shareables/theme';


// Export bottom margin
export const fieldContainerBottomMargin = '1.25em';


// The container that holds an entire field (label, control group, error, etc.)
const StyledFieldContainer = styled.div`
	&:not(:last-child) {
		margin-bottom: ${fieldContainerBottomMargin};
	}
	
	.iti {
		width: 100%;
		
		.iti__selected-flag {
			borderRadius: '0.2em',
			borderStyle: '1px solid #000',
			border-color: transparent;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			
			&:focus {
				border-color: ${(props) => (props.theme.hasError ? theme.colors.danger : theme.colors.primary)};
				outline: none;
				box-shadow: 0 0 0 0.125em
					${(props) => transparentize(0.75, props.theme.hasError ? theme.colors.danger : theme.colors.primary)};
			}
		}
		
		ul.iti__country-list {
			margin: 0.5em 0 0 -1px;
			border: 1px solid #ccc;
			border-radius: ${(props) => props.theme.borderRadius};
			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
			
			li.iti__divider {
				margin-top: 0;
				height: 0;
			}
		}
	}
	
	div.ck-editor {
		width: 100%;
		transition: all 0.1s ease;
		border: ${(props) => props.theme.borderStyle};
		border-color: ${(props) => (props.theme.hasError ? theme.colors.danger : '#ddd')};
		border-radius: ${(props) => props.theme.borderRadius};
		
		&:hover:not(:disabled) {
			border-color: ${(props) => darken(0.1, props.theme.hasError ? theme.colors.danger : '#ddd')};
		}
		
		&:focus-within:not(:disabled) {
			border-color: ${(props) => (props.theme.hasError ? theme.colors.danger : theme.colors.primary)};
			outline: none;
			box-shadow: 0 0 0 0.125em
				${(props) => transparentize(0.75, props.theme.hasError ? theme.colors.danger : theme.colors.primary)};
		}
		
		.ck-toolbar {
			border: none;
		}
		
		.ck-content {
			border-top-color: var(--ck-color-base-border) !important;
			border-right: none !important;
			border-bottom: none !important;
			border-left: none !important;
			box-shadow: none !important;
		}
	}
	
	input[type="checkbox"], input[type="radio"] {
		margin: 0 0.5rem 0 0;
	}
	
	> legend {
		margin-bottom: 0.25em;
		padding-bottom: 0;
	}
`;


// Create theme
const fieldTheme = {
	borderRadius: '0.2em',
	borderStyle: '1px solid #000',
};


// Functional component
const FieldContainer: React.FC<any> = (props) => (
	<ThemeProvider theme={fieldTheme}>
		<StyledFieldContainer {...props} />
	</ThemeProvider>
);

export default FieldContainer;
