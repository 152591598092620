// Imports
import React, { PropsWithChildren, useState } from 'react';
import GlobalErrorBoundary from './resources/global-error-boundary';
import InvisibleErrorBoundary from './resources/invisible-error-boundary';
import PageMessages from '../../../../shareables/foundation/front-end/components/resources/page-messages';
import AutoHideSidebar from './resources/auto-hide-sidebar';
import ScrollBehaviorHandler from '../../front-end/components/resources/scroll-behavior-handler';
import UserFunctionality from './resources/user-functionality';
import Sidebar from './sidebar';
import Content from './content';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import theme from '../../../../shareables/theme';
import styled, { createGlobalStyle } from 'styled-components/macro';
import SkipToMainContent from './resources/skip-to-main-content';
import { setLightness } from 'polished';
import store from '../../../../shareables/foundation/front-end/redux/store';
import { Provider } from 'react-redux';
import BrowserVersionWarningBanner from './resources/browser-version-warning-banner';
import { ExtraUserDropdownItem } from './top-bar/user-dropdown';
import { z } from 'zod';
import NetworkRequestLoadingIndicator from './resources/network-request-loading-indicator';
import appRoutes from '../../../../app-routes';
import thirdPartyIdentifiers from '../../../third-party-identifiers';
import RouteChangeListener from '../../front-end/components/resources/route-change-listener';


// Define the accepted props
interface Props {
	/** The stylized title of the app. */
	name: string;
	
	
	/** The app’s tagline (shown in the sidebar). Can either be a string literal or a component. */
	tagline: string | React.ReactChild;
	
	
	/** Whether to display a small “BETA” tag in the sidebar near the title. Defaults to `false`. */
	isBeta?: true;
	
	
	/** Whether to hide options for contacting support from the top bar. Defaults to `false`. */
	disableSupportOptions?: true;
	
	
	/** A component that contains any extra components that need to wrap around the rest of the application. */
	extraWrappingComponents?: React.FC<PropsWithChildren>;
	
	
	/** An array of extra top-level components that will placed near the top of inside `<App />`. Particularly useful for listener-type components that don’t render anything. */
	extraTopLevelComponents?: React.ReactChild[];
	
	
	/** An array of extra user dropdown items that will placed above the “sign out” item. */
	extraUserDropdownItems?: ExtraUserDropdownItem[];
	
	
	/** The app's minimum browser version requirements to function as expected. Only necessary if this app requires higher minimum versions than the shareable components from Spark require. */
	minimumBrowserVersions?: React.ComponentProps<typeof BrowserVersionWarningBanner>['minimumVersions'];
	
	
	/** A UTM code to provide as a search parameter to external websites linked in the sidebar */
	utmSource?: string;
}


// Global style
export const GlobalStyle = createGlobalStyle`
	html {
		height: 100%;
	}
	
	body {
		margin: 0;
		padding: 0;
		padding-bottom: 5rem;
		font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
		text-size-adjust: 100%;
		font-variant-numeric: lining-nums;
		font-feature-settings:"lnum" 1;
		min-height: 100%;
		background: ${theme.colors.background};
		color: #4a4a4a;
		line-height: 1.5;
	}
	
	blockquote, body, dd, dl, dt, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, html, iframe, legend, li, ol, p, pre, textarea, ul {
		margin: 0;
		padding: 0;
	}
	
	*:not(.svg-inline--fa) {
		box-sizing: border-box;
	}
	
	.tippy-content {
		text-align: center;
	}
	
	a,
	span.is-link {
		color: ${theme.colors.link};
		cursor: pointer;
		text-decoration: none;
		
		&:hover {
			color: ${setLightness(0.29, theme.colors.primary)};
		}
	}
	
	a,
	span,
	small,
	svg {
		&.is-green {
			color: ${theme.colors.success} !important;
		}
		
		&.is-yellow {
			color: ${theme.colors.yellow} !important;
		}
		
		&.is-red {
			color: ${theme.colors.danger} !important;
		}
		
		&.is-gray {
			color: #7a7a7a !important;
		}
	}
	
	hr {
		background-color: #f5f5f5;
		border: none;
		display: block;
		height: 2px;
		margin: 1.5rem 0;
	}
	
	ul,
	ol {
		margin-left: 2em;
	}
	
	fieldset {
		border: 0;
	}
`;


// Styled components
const FlexContainer = styled.div`
	@media (min-width: ${theme.breakpoints.mobile}px) {
		display: flex;
	}
`;


/** The main component that should be rendered in the `index.tsx` file. */
const App: React.FC<Props> = ({
	name,
	tagline,
	isBeta,
	disableSupportOptions,
	extraWrappingComponents: ExtraWrappingComponents,
	extraTopLevelComponents,
	extraUserDropdownItems,
	minimumBrowserVersions,
	utmSource,
}) => {
	// Get app IDs
	const cannyAppID = thirdPartyIdentifiers.cannyAppID;
	const statuspageAppID = thirdPartyIdentifiers.statuspageAppID;
	
	
	// Use state
	const [title, setTitle] = useState(document.title);
	
	
	// Build inner content
	const innerContent = (
		<ScrollBehaviorHandler>
			{/* RouteChangeListener must be placed first in the BrowserRouter, so that it processes changes first */}
			<RouteChangeListener title={title} />
			<AutoHideSidebar />
			<UserFunctionality />
			
			{extraTopLevelComponents}
			
			<Helmet
				defaultTitle={name}
				titleTemplate={`%s // ${name}`}
				onChangeClientState={(newState) => {
					const clientStateSchema = z.object({
						title: z.string(),
					});
					
					const { title: newTitle } = clientStateSchema.parse(newState);
					
					setTitle(newTitle);
				}}
			/>
			
			<SkipToMainContent />
			
			<NetworkRequestLoadingIndicator />
			
			<FlexContainer>
				<PageMessages />
				
				<Sidebar
					name={name}
					tagline={tagline}
					isBeta={isBeta}
					cannyAppID={cannyAppID}
					statuspageAppID={statuspageAppID}
					utmSource={utmSource}
				/>
				
				<Content disableSupportOptions={disableSupportOptions} extraUserDropdownItems={extraUserDropdownItems} />
			</FlexContainer>
		</ScrollBehaviorHandler>
	);
	
	
	// Use React Router functionality
	let content = ExtraWrappingComponents ? (
		<ExtraWrappingComponents>{innerContent}</ExtraWrappingComponents>
	) : (
		innerContent
	);
	
	content = <GlobalErrorBoundary>{content}</GlobalErrorBoundary>;
	
	const router = createBrowserRouter(createRoutesFromElements(<Route element={content}>{appRoutes}</Route>));
	
	
	// Return JSX
	return (
		<React.StrictMode>
			<GlobalErrorBoundary>
				<GlobalStyle />
				
				<InvisibleErrorBoundary>
					<BrowserVersionWarningBanner minimumVersions={minimumBrowserVersions} />
				</InvisibleErrorBoundary>
				
				<GlobalErrorBoundary>
					<Provider store={store}>
						<HelmetProvider>
							<RouterProvider router={router} />
						</HelmetProvider>
					</Provider>
				</GlobalErrorBoundary>
			</GlobalErrorBoundary>
		</React.StrictMode>
	);
};

export default App;
