// Imports
import React, { useState, useEffect } from 'react';
import helperFunctions from '../../../../../shareables/foundation/front-end/utils/helper-functions';
import styled, { keyframes } from 'styled-components/macro';
import { transparentize } from 'polished';
import theme from '../../../../../shareables/theme';


// Styled components
const Container = styled.div`
	display: block;
	font-size: 0.65rem;
	line-height: 1;
	color: #fff;
	margin-bottom: 0.5rem;
`;

const pulse = keyframes`
	0% {
		box-shadow: 0 0 0.2rem 0 ${transparentize(0.5, theme.colors.danger)};
	}
	
	15% {
		box-shadow: 0 0 0.2rem 0.3rem ${transparentize(0.5, theme.colors.danger)};
	}
	
	40% {
		box-shadow: 0 0 0.2rem 0.3rem ${transparentize(1, theme.colors.danger)};
	}
	
	100% {
		box-shadow: 0 0 0.2rem 0.3rem ${transparentize(1, theme.colors.danger)};
	}
`;

const Dot = styled.span`
	display: inline-block;
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 9999rem;
	background: ${theme.colors.danger};
	animation-name: ${pulse};
	animation-duration: 3s;
	animation-delay: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
`;

const StopButton = styled.span`
	cursor: pointer;
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 2px;
	background: rgba(255, 255, 255, 0.1);
	padding: 0 0.15rem;
	line-height: 1.5;
	display: inline-block;
	transition: border-color 0.15s ease;
	
	&:hover {
		border-color: #fff;
	}
`;


// Let TypeScript know about third party tools
declare global {
	function Upscope(initializer: 'on', ...eventsWithHandler: [...string[], () => void]): void;
	function Upscope(command: 'stopSession'): void;
	
	interface Window {
		Upscope?: typeof Upscope;
	}
}


// Define the accepted props
interface Props {
	storybookActive?: true;
}


// Function component
const ScreenSharingIndicator: React.FC<Props> = ({ storybookActive }) => {
	// Use state
	const [screenSharingActive, setScreenSharingActive] = useState(false);
	
	
	// Listen for Upscope events
	useEffect(() => {
		if (window.Upscope) {
			window.Upscope('on', 'sessionStart', 'sessionContinue', () => {
				setScreenSharingActive(true);
			});
			
			window.Upscope('on', 'sessionEnd', () => {
				setScreenSharingActive(false);
			});
		}
	}, []);
	
	
	// Don't render anything if inactive
	if (!screenSharingActive && !storybookActive) {
		return null;
	}
	
	
	// Return JSX
	return (
		<Container>
			<Dot /> Screen sharing active;&nbsp;
			<StopButton {...helperFunctions.buttonize(() => window.Upscope('stopSession'))}>stop</StopButton>
		</Container>
	);
};

export default ScreenSharingIndicator;
