// Imports
import React from 'react';
import Heading from '../../shareables/foundation/admin-ui/components/content/heading';
import Box from '../../shareables/foundation/admin-ui/components/content/box';
import Select from '../../shareables/foundation/front-end/components/forms/fields/select';
import { GetWebsitesResponse } from '../../shareables/types/api/main/self/websites/get';
import useCachedState from '../../shareables/foundation/front-end/utils/use-cached-state';
import { z } from 'zod';


// A Zod schema to parse website objects
export const websiteSchema: z.ZodType<GetWebsitesResponse[0]> = z.object({
	id: z.number(),
	communityName: z.string(),
	url: z.string(),
	startProcessComplete: z.boolean(),
	onboardingChecklistComplete: z.boolean(),
	privileges: z.array(
		z.union([
			z.literal('*'),
			z.literal('BILLING'),
			z.literal('COMMUNICATIONS'),
			z.literal('DOMAINS'),
			z.literal('EMAIL_ACCOUNTS'),
			z.literal('MEMBERS'),
			z.literal('PAGES'),
			z.literal('PAYMENTS'),
			z.literal('SURVEYS'),
		])
	),
	country: z.object({
		code: z.string(),
		twoLetterCode: z.string(),
	}),
	deluxePlan: z.boolean(),
	globalLogIn: z.literal(true).optional(),
	formatting: z.object({
		dateFormat: z.union([z.literal('MDY'), z.literal('DMY')]),
		timeFormat: z.union([z.literal('12HR'), z.literal('24HR'), z.literal('24HR_NO_COLON')]),
	}),
	salesTaxExemptionCertificate: z.boolean(),
});


// Function component
const SelectWebsite: React.FC = () => {
	// Use cached state
	const [, setWebsite] = useCachedState<GetWebsitesResponse[0] | null>(null, 'website');
	const [websites] = useCachedState<GetWebsitesResponse | null>(null, 'websites');
	
	
	// Handle select change
	const handleSelectChange = (newValue: string | number) => {
		setWebsite(websiteSchema.parse(JSON.parse(String(newValue))));
	};
	
	
	// Handle no eligible communities
	if (websites && websites.length === 0) {
		return (
			<React.Fragment>
				<Heading size={1}>No eligible communities</Heading>
				
				<Box>It looks like you’re not a member of any communities where you have administrative privileges.</Box>
			</React.Fragment>
		);
	}
	
	
	// Return JSX
	return (
		<React.Fragment>
			<Heading size={1}>Please select a community</Heading>
			
			<Box>
				<Select name='community' onChange={handleSelectChange} placeholder='' autoFocus>
					{websites &&
						websites.map((website) => {
							return (
								<option key={website.id} value={JSON.stringify(website)}>
									{website.communityName}
								</option>
							);
						})}
				</Select>
			</Box>
		</React.Fragment>
	);
};

export default SelectWebsite;
