// Imports
import { State, Actions, TOGGLE_SIDEBAR, HIDE_SIDEBAR, PWA_ACTIVE, NEW_VERSION_IS_AVAILABLE } from './types';


// Define initial state
const initialState: State = {
	sidebar: {
		isVisible: false,
	},
	newVersionIsAvailable: false,
	pwa: {
		active: false,
	},
};


// The root reducer
export default function reducer(state = initialState, action: Actions): State {
	switch (action.type) {
		case TOGGLE_SIDEBAR:
			return {
				...state,
				sidebar: {
					...state.sidebar,
					isVisible: !state.sidebar.isVisible,
				},
			};
		case HIDE_SIDEBAR:
			return {
				...state,
				sidebar: {
					...state.sidebar,
					isVisible: false,
				},
			};
		case PWA_ACTIVE:
			return {
				...state,
				pwa: {
					...state.pwa,
					active: true,
				},
			};
		case NEW_VERSION_IS_AVAILABLE:
			return {
				...state,
				newVersionIsAvailable: true,
			};
		default:
			return state;
	}
}
