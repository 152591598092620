// Imports
import styled from 'styled-components/macro';
import { transparentize, darken } from 'polished';
import theme from '../../../../../shareables/theme';


// The container that holds the input itself, along with any icons that overlay it
const InputContainer = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
	position: relative;
	box-shadow: ${(props) => props.theme.boxShadow ?? ''};
	
	&:not(:first-child) {
		input,
		select,
		textarea {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	
	&:not(:last-child) {
		input,
		select,
		textarea {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	
	input,
	select,
	textarea {
		width: 100%;
		height: 2.25em;
		appearance: none;
		background: #fff;
		font-size: 1em;
		font-family: inherit;
		color: #000;
		transition: all 0.1s ease;
		border: ${(props) => props.theme.borderStyle};
		border-color: ${(props) => (props.theme.hasError ? theme.colors.danger : props.theme.borderColor ?? '#ddd')};
		border-radius: ${(props) => props.theme.borderRadius};
		line-height: 1.3;
		
		&:hover:not(:disabled) {
			border-color: ${(props) => darken(0.1, props.theme.hasError ? theme.colors.danger : '#ddd')};
		}
		
		&:focus:not(:disabled) {
			border-color: ${(props) => (props.theme.hasError ? theme.colors.danger : theme.colors.primary)};
			outline: none;
			box-shadow: 0 0 0 0.125em
				${(props) => transparentize(0.75, props.theme.hasError ? theme.colors.danger : theme.colors.primary)};
		}
		
		&:disabled {
			background: #eee;
			opacity: 0.7;
			color: graytext;
		}
		
		&::placeholder {
			color: #999;
		}
	}
	
	> input {
		padding: 0 0.75em;
	}
	
	input {
		padding-top: 0;
		padding-bottom: 0;
	}
	
	select {
		padding: 0 0.75em;
		padding-right: 2em;
		
		:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 #000;
		}
	}
	
	textarea {
		height: inherit;
		padding: 0 0.75em;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}
	
	.StripeElement {
		background: #fff;
		height: 2.25em;
		display: flex;
		align-items: center;
		padding: 0 0.75em;
		transition: all 0.1s ease;
		border: ${(props) => props.theme.borderStyle};
		border-color: ${(props) => (props.theme.hasError ? theme.colors.danger : '#ddd')};
		border-radius: ${(props) => props.theme.borderRadius};
		
		&:hover {
			border-color: ${(props) => darken(0.1, props.theme.hasError ? theme.colors.danger : '#ddd')};
		}
		
		&.StripeElement--focus {
			border-color: ${(props) => (props.theme.hasError ? theme.colors.danger : theme.colors.primary)};
			outline: none;
			box-shadow: 0 0 0 0.125em
				${(props) => transparentize(0.75, props.theme.hasError ? theme.colors.danger : theme.colors.primary)};
		}
		
		> div {
			width: 100%;
		}
	}
`;

export default InputContainer;
