// Imports
import styled, { css } from 'styled-components/macro';


// Break off and export styling separately
export const styling = css`
	border-radius: 3px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #fff;
	padding: 1.25rem;
	overflow-wrap: break-word;
	
	&:not(:last-child) {
		margin-bottom: 1.5rem;
	}
	
	blockquote:not(:last-child),
	dl:not(:last-child),
	ol:not(:last-child),
	p:not(:last-child),
	pre:not(:last-child),
	table:not(:last-child),
	ul:not(:last-child) {
		margin-bottom: 1em;
	}
	
	code {
		background: #eee;
		padding: 0.1em 0.3em;
		border-radius: 0.3em;
		border: 0.1em solid #ddd;
	}
	
	pre {
		max-width: 100%;
		overflow-x: auto;
		
		> code {
			width: fit-content;
			display: block;
		}
	}
	
	blockquote {
		padding: 0.5em 1em;
		border-left: 0.3em solid #ccc;
	}
`;


// Styled component
const Box = styled.section`
	${styling}
`;

export default Box;
