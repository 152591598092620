// Imports
import styled from 'styled-components/macro';
import theme from '../../../../../shareables/theme';


// An item that appears separate from, and next to, the input itself (prefix, suffix, etc.)
const AddOn = styled.div`
	height: 2.25em;
	flex-grow: 0;
	flex-shrink: 0;
	padding: 0.4em 0.75em;
	background: #f5f5f5;
	color: #7a7a7a;
	border: ${(props) => props.theme.borderStyle};
	border-color: ${(props) => (props.theme.hasError ? theme.colors.danger : '#ddd')};
	border-radius: ${(props) => props.theme.borderRadius};
	line-height: 1.45;
	
	&:first-child {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: 0;
	}
	
	&:last-child {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 0;
	}
`;

export default AddOn;
