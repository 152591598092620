// Imports
import React, { Suspense } from 'react';
import TopBar from '../top-bar';
import SuspenseFallback from '../../../../../shareables/foundation/front-end/components/resources/suspense-fallback';
import styled from 'styled-components/macro';
import theme from '../../../../../shareables/theme';
import { ExtraUserDropdownItem } from '../top-bar/user-dropdown';
import { sidebarWidth } from '../sidebar';
import { Outlet } from 'react-router-dom';


// Styled components
export const Wrapper = styled.div`
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
	margin: 0;
	padding: 0;
	
	@media (min-width: ${theme.breakpoints.mobile + 1}px) {
		&:not(:first-child) {
			margin-left: ${sidebarWidth};
			max-width: calc(100% - ${sidebarWidth});
		}
	}
	
	
	// Responsive styling
	@media (max-width: ${theme.breakpoints.mobile}px) {
		margin-left: 0;
		max-width: 100%;
	}
`;

export const Container = styled.main`
	width: 100%;
	max-width: 75rem;
	margin: auto;
	padding: 2rem;
	padding-bottom: 10rem;
	
	
	// Responsive styling
	@media (max-width: ${theme.breakpoints.mobile}px) {
		padding-top: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}
`;


// Define the accepted props
interface Props {
	disableSupportOptions?: true;
	extraUserDropdownItems?: ExtraUserDropdownItem[];
	storybookSupportNumber?: string;
	storybookProductionDomain?: string;
}


// Function component
const Content: React.FC<Props> = ({
	disableSupportOptions,
	extraUserDropdownItems,
	storybookSupportNumber,
	storybookProductionDomain,
}) => {
	// Return JSX
	return (
		<Wrapper>
			<TopBar
				disableSupportOptions={disableSupportOptions}
				extraUserDropdownItems={extraUserDropdownItems}
				storybookSupportNumber={storybookSupportNumber}
				storybookProductionDomain={storybookProductionDomain}
			/>
			
			<Container role='main' id='main-content'>
				<Suspense fallback={<SuspenseFallback />}>
					<Outlet />
				</Suspense>
			</Container>
		</Wrapper>
	);
};

export default Content;
