// Imports
import styled, { css } from 'styled-components/macro';
import { setLightness, setSaturation } from 'polished';
import theme from '../../../../../shareables/theme';
import React from 'react';


// Define the accepted props
interface Props extends React.PropsWithChildren {
	size: 1 | 2 | 3 | 4;
	level?: 1 | 2 | 3 | 4 | 5 | 6;
	centered?: boolean;
}

type CompleteProps = Props &
	Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>, 'ref'>;


// Styled component
type WrapperProps = Omit<CompleteProps, 'children' | 'level'>;

const Wrapper = styled.h1<WrapperProps>`
	line-height: 1;
	color: ${setSaturation(0.25, setLightness(0.24, theme.colors.primary))};
	font-weight: 400;
	margin-bottom: 0.25em;
	
	&:last-child {
		margin-bottom: 0;
	}
	
	${({ size }) =>
		size === 1
			? css`
					font-size: 1.875rem;
					font-weight: 300;
					margin-bottom: 0.5em;
			  `
			: ''}
	
	${({ size }) =>
		size === 2
			? css`
					font-size: 1.4rem;
					margin-bottom: 0.5714em;
			  `
			: ''}
	
	${({ size }) =>
		size === 3
			? css`
					font-size: 1.15rem;
			  `
			: ''}
	
	${({ size }) =>
		size === 4
			? css`
					font-size: 1.075rem;
					font-weight: 500;
			  `
			: ''}
	
	${({ centered }) =>
		centered
			? css`
					text-align: center;
			  `
			: ''}
`;


// Function component
const Heading: React.FC<CompleteProps> = ({ children, level, size, ...props }) => {
	return (
		<Wrapper as={`h${level ?? size}`} size={size} {...props}>
			{children}
		</Wrapper>
	);
};

export default Heading;
