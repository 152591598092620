// Imports
import styled from 'styled-components/macro';


// An container that holds an icon that overlays an input
interface InputIconHolderProps {
	right?: boolean;
	color?: string;
}

const InputIconHolder = styled.span<InputIconHolderProps>`
	width: 2.25em;
	height: 2.25em;
	color: ${(props) => props.color || props.theme.iconColor || '#dbdbdb'};
	pointer-events: none;
	position: absolute;
	top: 0;
	${(props) => (props.right ? 'right: 0;' : 'left: 0;')}
	z-index: 1;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	
	+ input,
	+ select {
		padding-left: 2.25em;
	}
`;

export default InputIconHolder;
