// Imports
import React from 'react';
import { useAppSelector } from '../../../front-end/redux/hooks';
import styled from 'styled-components/macro';
import HideSidebarButton from './hide-sidebar-button';
import { setLightness, setSaturation } from 'polished';
import theme from '../../../../../shareables/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import company from '../../../../company';


// Styled components
const Container = styled.header`
	outline: 0;
`;

const CompanyInfo = styled.div`
	margin: 0;
	padding: 18px 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Logo = styled.a`
	height: 15px;
	font-size: 15px;
	
	> img {
		height: inherit;
	}
`;

const Separator = styled.div`
	width: 1px;
	height: 15px;
	margin: 0 10px;
	background: ${setSaturation(0.1, setLightness(0.45, theme.colors.primary))};
	display: inline-block;
`;

const SocialIcon = styled.a`
	font-size: 12px;
	color: ${setSaturation(0.29, setLightness(0.72, theme.colors.primary))};
	transition: all 0.2s ease;
	
	&:not(:last-child) {
		margin-right: 10px;
	}
	
	&:hover {
		color: #fff;
	}
`;

const AppName = styled.div`
	padding: 0 0.5rem 0.25rem;
	font-size: 1.75rem;
	font-weight: 200;
	color: #fff;
	text-align: center;
`;

const BetaTag = styled.div`
	padding: 0.1rem 0.2rem 0.2rem;
	background: ${setSaturation(0.25, setLightness(0.37, theme.colors.primary))};
	border-radius: 0.2rem;
	position: absolute;
	top: 2.9rem;
	right: 2rem;
	color: ${setSaturation(0.27, setLightness(0.8, theme.colors.primary))};
	line-height: 1;
	font-size: 60%;
`;

const Tagline = styled.div`
	margin: 0 0.5rem;
	font-family: 'Times New Roman', serif;
	font-size: 0.875rem;
	font-style: italic;
	color: ${setSaturation(0.25, setLightness(0.6, theme.colors.primary))};
	text-align: center;
	hyphens: auto;
`;


// Define the accepted props
interface Props {
	name: string;
	isBeta?: true;
	tagline: string | React.ReactChild;
}


// Function component
const SidebarHeader: React.FC<Props> = ({ name, isBeta, tagline }) => {
	// Use Redux functionality
	const sidebarIsVisible = useAppSelector((state) => state.adminUI.sidebar.isVisible);
	
	
	// Return JSX
	return (
		<Container id='header' tabIndex={-1}>
			{sidebarIsVisible && <HideSidebarButton />}
			
			<CompanyInfo>
				<Logo
					href={`https://www.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}/`}
					target='_blank'
					rel='noopener noreferrer'
					aria-label={`${company.name} main website`}
				>
					<img src='/images/logo.png' alt='Company logo' />
				</Logo>
				
				<Separator />
				
				<SocialIcon
					href={`https://www.facebook.com/${company.socialSlugs.facebook}`}
					target='_blank'
					rel='noopener noreferrer'
					className='social'
					aria-label={`${company.name} on Facebook`}
				>
					<FontAwesomeIcon icon={faFacebookF} />
				</SocialIcon>
				
				<SocialIcon
					href={`https://twitter.com/${company.socialSlugs.x}`}
					target='_blank'
					rel='noopener noreferrer'
					className='social'
					aria-label={`${company.name} on X`}
				>
					<FontAwesomeIcon icon={faXTwitter} />
				</SocialIcon>
				
				<SocialIcon
					href={`https://www.linkedin.com/company/${company.socialSlugs.linkedIn}`}
					target='_blank'
					rel='noopener noreferrer'
					className='social'
					aria-label={`${company.name} on LinkedIn`}
				>
					<FontAwesomeIcon icon={faLinkedinIn} />
				</SocialIcon>
			</CompanyInfo>
			
			<AppName>{name}</AppName>
			
			{isBeta && <BetaTag>BETA</BetaTag>}
			
			<Tagline>{tagline}</Tagline>
		</Container>
	);
};

export default SidebarHeader;
