// Imports
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../front-end/redux/hooks';
import apiActions from '../../../../../shareables/integrations/api/redux/actions';
import commonActions from '../../../../../shareables/foundation/front-end/redux/actions';
import * as Sentry from '@sentry/browser';
import { GetSelfResponse } from '../../../../types/api/main/self/get';


// Let TypeScript know about third party tools
declare global {
	function Upscope(
		command: 'updateConnection',
		options?: {
			identities: string[];
			uniqueId: number;
		}
	): void;
	
	interface Window {
		Upscope?: typeof Upscope;
	}
}


// Function component
const UserFunctionality: React.FC = () => {
	// Use Redux functionality
	const dispatch = useAppDispatch();
	const signedIn = useAppSelector((state) => state.common.user.signedIn);
	
	
	// Attempt to refresh the auth cookie
	useEffect(() => {
		void fetch(`https://auth.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}/keep-alive`, {
			method: 'GET',
			mode: 'no-cors',
			cache: 'no-cache',
			credentials: 'include',
		});
	}, []);
	
	
	// Require user to sign in and retrieve details about them
	useEffect(() => {
		// Handle based on whether the user is signed in
		if (!signedIn) {
			// Call API
			void dispatch(
				apiActions.call<
					undefined,
					undefined,
					GetSelfResponse extends { intercom: unknown }
						? GetSelfResponse['intercom'] extends Intercom_.IntercomSettings
							? GetSelfResponse
							: never
						: GetSelfResponse & { intercom?: never }
				>({
					action: 'GET',
					uri: '/self',
					cancelOnRouteChange: false,
					completion: (json) => {
						// Dispatch action
						dispatch(
							commonActions.signedIn({
								id: json.response.id,
								name: json.response.name,
								firstName: json.response.firstName,
								lastName: json.response.lastName,
								email: json.response.email,
								profilePhotoURL: json.response.profilePhotoURL,
							})
						);
						
						
						// Boot Intercom, if available
						if (window.Intercom && 'intercom' in json.response && json.response.intercom) {
							window.Intercom('boot', json.response.intercom);
						}
						
						
						// Initialize Upscope, if available
						if (window.Upscope) {
							window.Upscope('updateConnection', {
								identities: [json.response.name, json.response.email],
								uniqueId: json.response.id,
							});
						}
						
						
						// Store user for Sentry
						Sentry.configureScope((scope) => {
							scope.setUser({
								id: String(json.response.id),
								email: json.response.email,
							});
						});
					},
				})
			);
		}
	}, [signedIn, dispatch]);
	
	
	// Render nothing
	return null;
};

export default UserFunctionality;
