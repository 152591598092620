// Imports
import React from 'react';


// Define what the context looks like
export type ContextDetails = {
	addValueGetter: (name: string, getter: () => string | number | boolean) => void;
	removeValueGetter: (name: string) => void;
	addFileGetter: (name: string, getter: () => File | FileList | null) => void;
	removeFileGetter: (name: string) => void;
	addTokenGetter: (name: string, getter: () => Promise<string>) => void;
	removeTokenGetter: (name: string) => void;
	addErrorContainer: (name: string) => void;
	removeErrorContainer: (name: string) => void;
	setError: (field: string, message: string) => void;
	clearErrors: (fieldToClear?: string) => void;
	error: {
		field: string | null;
		message: string | null;
	};
	onChange: (shouldPreventNavigation?: true) => void;
	onExcludeChange: () => void;
	recoverState?: {
		enabled: boolean;
		uniqueIdentifier: string;
	};
	v2ChallengeActive: boolean;
} | null;


// Create context
const APIConnectedFormContext = React.createContext<ContextDetails>(null);

export default APIConnectedFormContext;
