// Imports
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import tippyProps from '../../../../../shareables/foundation/front-end/utils/tippy-props';
import { setLightness, setSaturation } from 'polished';
import theme from '../../../../../shareables/theme';
import NotificationCount from '../resources/notification-count';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronsRight } from '@fortawesome/pro-regular-svg-icons';
import helperFunctions from '../../../../../shareables/foundation/front-end/utils/helper-functions';


// Styled components
interface ContainerProps {
	isDisabled?: boolean; // Cannot use `disabled` because it also disables Tippy
	storybookActive?: boolean;
}

const Container = styled.li<ContainerProps>`
	list-style: none;
	
	> a,
	> span {
		padding: 0.6875rem 1.25rem;
		padding-right: 1.5rem;
		font-size: 1.125rem;
		font-weight: 300;
		color: #fff;
		transition: all 0.2s ease;
		justify-content: left;
		cursor: pointer;
		text-decoration: none;
		display: flex;
		align-items: center;
		background: ${setSaturation(0.25, setLightness(0.25, theme.colors.primary))};
		
		&.active {
			background: ${setSaturation(0.24, setLightness(0.28, theme.colors.primary))};
			
			svg.fa-chevrons-right {
				display: inline;
			}
		}
		
		&:hover {
			color: #fff;
			background: ${setSaturation(0.25, setLightness(0.32, theme.colors.primary))};
		}
		
		svg:not(.fa-chevrons-right) {
			margin-right: 0.5rem;
			font-size: 1rem;
			color: ${setSaturation(0.56, setLightness(0.69, theme.colors.info))};
		}
		
		svg.fa-chevrons-right {
			font-size: 0.875rem;
			color: ${setSaturation(0.25, setLightness(0.2, theme.colors.primary))};
			position: absolute;
			left: calc(100% - 1.55rem);
			display: none;
		}
		
		${(props) =>
			props.storybookActive
				? css`
						background: ${setSaturation(0.24, setLightness(0.28, theme.colors.primary))};
						
						svg.fa-chevrons-right {
							display: inline;
						}
				  `
				: ''}
	}
	
	${(props) =>
		props.isDisabled
			? css`
					opacity: 0.5;
					outline: none;
					
					> a,
					> span {
						cursor: default;
					}
			  `
			: ''}
`;


// Define the accepted props
interface Props {
	text: string;
	icon: IconProp;
	to?: string;
	href?: string;
	onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
	disabled?: boolean;
	tooltip?: string;
	notificationCount?: number;
	storybookActive?: boolean;
	'data-intercom-target'?: string;
}


// Function component
const SidebarLink: React.FC<Props> = ({
	text,
	icon,
	to,
	href,
	onClick,
	storybookActive,
	disabled,
	tooltip,
	notificationCount,
	...props
}) => {
	// The inner-most elements
	const innerMostContent = (
		<React.Fragment>
			<FontAwesomeIcon icon={icon} fixedWidth />
			{text}
			{notificationCount !== undefined && <NotificationCount>{notificationCount.toLocaleString()}</NotificationCount>}
			<FontAwesomeIcon icon={faChevronsRight} />
		</React.Fragment>
	);
	
	
	// Return the disabled sidebar link with a tooltip
	if (disabled && tooltip) {
		/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
		return (
			<Tippy {...tippyProps} content={tooltip} offset={[0, -5]} hideOnClick={false}>
				<Container tabIndex={-1} storybookActive={storybookActive} isDisabled>
					<span>{innerMostContent}</span>
				</Container>
			</Tippy>
		);
		/* eslint-enable jsx-a11y/no-noninteractive-tabindex */
	}
	
	
	// Return the fully-functional sidebar link
	return (
		<Container storybookActive={storybookActive}>
			{to ? (
				<NavLink to={to} data-intercom-target={props['data-intercom-target']}>
					{innerMostContent}
				</NavLink>
			) : href ? (
				<a href={href} target='_blank' rel='noopener noreferrer' data-intercom-target={props['data-intercom-target']}>
					{innerMostContent}
				</a>
			) : onClick ? (
				<span {...helperFunctions.buttonize(onClick)} data-intercom-target={props['data-intercom-target']}>
					{innerMostContent}
				</span>
			) : null}
		</Container>
	);
};

export default SidebarLink;
