// Imports
import React from 'react';
import styled from 'styled-components/macro';
import helperFunctions from '../../../../../shareables/foundation/front-end/utils/helper-functions';
import { useAppSelector } from '../../../front-end/redux/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import theme from '../../../../../shareables/theme';
import company from '../../../../company';


// Styled components
const Container = styled.nav`
	margin-right: 2rem;
	font-size: 0.8rem;
	color: #fff;
	font-weight: 300;
	flex-shrink: 0;
	
	strong {
		color: #fff;
		font-weight: 500;
	}
	
	a,
	span {
		color: #fff !important;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		transition: all 0.2s ease;
		cursor: pointer;
		text-decoration: none;
		
		&:hover {
			border-bottom: 1px solid rgba(255, 255, 255, 1);
		}
	}
	
	svg.fa-arrow-up-right-from-square {
		margin-left: 0.25rem;
		font-size: 0.5rem;
		opacity: 0.5;
	}
	
	@media (max-width: ${theme.breakpoints.mobile}px) {
		display: none;
		visibility: hidden;
	}
`;


// Define the accepted props
interface Props {
	storybookSupportNumber?: string;
	storybookProductionDomain?: string;
}


// Function component
const SupportOptions: React.FC<Props> = ({ storybookSupportNumber, storybookProductionDomain }) => {
	// Use Redux functionality
	const signedIn = useAppSelector((state) => state.common.user.signedIn);
	
	
	// Define support number
	const supportNumber = storybookSupportNumber ?? company.supportNumber;
	
	
	// Define production domain
	const productionDomain = storybookProductionDomain ?? company.productionDomain;
	
	
	// These should always be defined when this is rendered
	if (!supportNumber || !productionDomain) {
		throw new Error('Support options used without support number or production domain being defined');
	}
	
	
	// Return JSX
	return (
		<Container className='support-options'>
			<strong>Questions?</strong>&nbsp;
			<a href={`tel:${supportNumber?.replace(/[^+0-9]/g, '')}`} style={{ border: 0 }}>
				{supportNumber}
			</a>
			{Boolean(window.Intercom) && signedIn ? (
				<React.Fragment>
					&nbsp;&nbsp;|&nbsp;&nbsp;
					<span
						{...helperFunctions.buttonize(() => {
							window.Intercom('showNewMessage');
						})}
					>
						Live chat
					</span>
				</React.Fragment>
			) : null}
			{productionDomain && (
				<React.Fragment>
					&nbsp;&nbsp;|&nbsp;&nbsp;
					<a href={`https://help.${productionDomain}/`} target='_blank' rel='noopener noreferrer'>
						Help center
					</a>
				</React.Fragment>
			)}
			<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
		</Container>
	);
};

export default SupportOptions;
