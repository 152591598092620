// Imports
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';
import { animateFill, AnimateFill } from 'tippy.js';


/** A spreadable object that customizes Tippy’s behavior and appearance to ensure a standardized UI/UX experience */
const tippyProps: {
	offset: [number, number];
	animateFill: boolean;
	plugins: AnimateFill[];
	arrow: boolean;
} = {
	offset: [0, 5],
	animateFill: true,
	plugins: [animateFill],
	arrow: false,
};

export default tippyProps;
