// Imports
import { useEffect } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import commonActions from '../../../../../shareables/foundation/front-end/redux/actions';


// Function component
const SuspenseFallback: React.FC = () => {
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Use an effect to increment/decrement network requests
	useEffect(() => {
		// Increment the network request count
		dispatch(commonActions.incrementActiveRequestCount());
		
		
		// Decrement the network request count when destroyed
		return () => {
			dispatch(commonActions.decrementActiveRequestCount());
		};
	}, [dispatch]);
	
	
	// Return null
	return null;
};

export default SuspenseFallback;
