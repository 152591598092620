// Imports
import React, { useEffect, useState } from 'react';
import Heading from '../../shareables/foundation/admin-ui/components/content/heading';
import Box from '../../shareables/foundation/admin-ui/components/content/box';
import { useAppDispatch } from '../../shareables/foundation/front-end/redux/hooks';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons';
import apiActions from '../../shareables/integrations/api/redux/actions';
import { GetCannyResponse } from '../../shareables/types/api/main/self/sso/canny/get';


// Styled component
const LoadingWrapper = styled.div`
	text-align: center;
	font-style: italic;
`;


// Function component
const CannySSO: React.FC = () => {
	// Use state
	const [ssoToken, setSSOToken] = useState<string | null>(null);
	
	
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Use React Router functionality
	const [searchParams] = useSearchParams();
	const redirect = (window as typeof window & { STORYBOOK?: true }).STORYBOOK
		? 'TEST'
		: searchParams.get('redirect')?.replace('hoaexpress.canny.io', 'feedback.hoa-express.com');
	
	const companyID = (window as typeof window & { STORYBOOK?: true }).STORYBOOK ? 'TEST' : searchParams.get('companyID');
	
	if (!redirect) {
		throw new Error('Canny did not provide a `redirect` parameter');
	}
	
	if (!companyID) {
		throw new Error('Canny did not provide a `companyID` parameter');
	}
	
	
	// Load Canny SSO token on mount
	useEffect(() => {
		void dispatch(
			apiActions.call<undefined, undefined, GetCannyResponse>({
				action: 'GET',
				uri: '/self/sso/canny',
				completion: (json) => {
					setSSOToken(json.response);
				},
			})
		);
	}, [redirect, companyID, dispatch]);
	
	
	// Redirect after SSO token loads
	if (ssoToken) {
		window.location.href = `https://canny.io/api/redirects/sso?${searchParams.toString()}&ssoToken=${window.encodeURIComponent(
			ssoToken
		)}`;
	}
	
	
	// Return JSX
	return (
		<React.Fragment>
			<Heading size={1}>Redirecting</Heading>
			
			<Box>
				<LoadingWrapper>
					<FontAwesomeIcon icon={faArrowsRotate} spin fixedWidth /> Loading…
				</LoadingWrapper>
			</Box>
		</React.Fragment>
	);
};

export default React.memo(CannySSO);
