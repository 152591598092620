// Imports
import styled from 'styled-components/macro';


// The container that holds the elements that make up the control (prefix, icon, input, suffix, etc.)
const ControlGroup = styled.div`
	display: flex;
	position: relative;
`;

export default ControlGroup;
