// Imports
import React, { useEffect } from 'react';
import SidebarLink from './shareables/foundation/admin-ui/components/sidebar/link';
import {
	faGaugeSimpleHigh,
	faCopy,
	faUsers,
	faScreenUsers,
	faArrowUpRightFromSquare,
	faClockRotateLeft,
	faAt,
	faChartPie,
	faChartMixed,
	faPaperPlane,
	faMoneyBillWave,
} from '@fortawesome/pro-light-svg-icons';
import { useAppDispatch, useAppSelector } from './shareables/foundation/front-end/redux/hooks';
import { GetAccountsResponse, GetAccountsParameters } from './shareables/types/api/main/website/admin/accounts/get';
import apiActions from './shareables/integrations/api/redux/actions';
import selfActions from './redux/self/actions';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { GetWebsitesResponse } from './shareables/types/api/main/self/websites/get';
import useCachedState from './shareables/foundation/front-end/utils/use-cached-state';
import { Privilege } from './shareables/utils/privileges';


// Function component
const SidebarLinks: React.FC = () => {
	// Use Redux functionality
	const dispatch = useAppDispatch();
	const signedIn = useAppSelector((state) => state.common.user.signedIn);
	const pendingAccounts = useAppSelector((state) => state.self.pendingAccounts);
	
	
	// Use cached state
	const [website] = useCachedState<GetWebsitesResponse[0] | null>(null, 'website');
	const [websites] = useCachedState<GetWebsitesResponse | null>(null, 'websites');
	
	
	// Pick out website ID
	const websiteID = website?.id;
	
	
	// Check for pending accounts
	useEffect(() => {
		if (websiteID) {
			// Call API
			void dispatch(
				apiActions.call<GetAccountsParameters, undefined, GetAccountsResponse>({
					action: 'GET',
					uri: `/website/${websiteID}/admin/accounts`,
					parameters: {
						approved: false,
					},
					cancelOnRouteChange: false,
					handleErrorsAutomatically: false,
					completion: (json) => {
						if (json.status === 'success') {
							dispatch(selfActions.storePendingAccounts(json.response));
						}
					},
				})
			);
		} else {
			dispatch(selfActions.storePendingAccounts([]));
		}
	}, [websiteID, dispatch]);
	
	
	// Component for a sidebar link that renders based on privileges
	interface LinkProps {
		to: string;
		icon: IconProp;
		text: string;
		requiredPrivilege: Privilege;
		notificationCount?: number;
		'data-intercom-target'?: string;
	}
	
	const PrivilegesBasedSidebarLink: React.FC<LinkProps> = ({
		to,
		icon,
		text,
		requiredPrivilege,
		notificationCount,
		...props
	}) => {
		// Define the required privilege(s) tooltip text
		const requiredPrivilegeMessage =
			requiredPrivilege === '*'
				? 'Requires full admin privileges'
				: `Requires the “${requiredPrivilege.toLowerCase().replace(/_/g, ' ')}” privilege`;
		
		
		// Determine if disabled
		const disabled = Boolean(
			website?.privileges && !website?.privileges.includes('*') && !website?.privileges.includes(requiredPrivilege)
		);
		
		
		// Return JSX
		return (
			<SidebarLink
				to={to}
				icon={icon}
				text={text}
				disabled={disabled}
				tooltip={disabled ? requiredPrivilegeMessage : undefined}
				notificationCount={notificationCount}
				data-intercom-target={props['data-intercom-target']}
			/>
		);
	};
	
	
	// Return JSX
	return (
		<React.Fragment>
			<SidebarLink
				to='/dashboard'
				icon={faGaugeSimpleHigh}
				text='Dashboard'
				data-intercom-target='sidebar-link-dashboard'
			/>
			
			<PrivilegesBasedSidebarLink
				to='/pages'
				icon={faCopy}
				text='Pages'
				requiredPrivilege='PAGES'
				data-intercom-target='sidebar-link-pages'
			/>
			
			<PrivilegesBasedSidebarLink
				to='/members'
				icon={faUsers}
				text='Members'
				requiredPrivilege='MEMBERS'
				notificationCount={pendingAccounts.length || undefined}
				data-intercom-target='sidebar-link-members'
			/>
			
			<PrivilegesBasedSidebarLink
				to='/groups'
				icon={faScreenUsers}
				text='Groups'
				requiredPrivilege='MEMBERS'
				data-intercom-target='sidebar-link-groups'
			/>
			
			<PrivilegesBasedSidebarLink
				to='/communications'
				icon={faPaperPlane}
				text='Communications'
				requiredPrivilege='COMMUNICATIONS'
				data-intercom-target='sidebar-link-communications'
			/>
			
			<PrivilegesBasedSidebarLink
				to='/surveys'
				icon={faChartPie}
				text='Surveys'
				requiredPrivilege='SURVEYS'
				data-intercom-target='sidebar-link-surveys'
			/>
			
			<PrivilegesBasedSidebarLink
				to='/online-payments'
				icon={faMoneyBillWave}
				text='Online payments'
				requiredPrivilege='PAYMENTS'
				data-intercom-target='sidebar-link-online-payments'
			/>
			
			<PrivilegesBasedSidebarLink
				to='/visitor-tracking'
				icon={faChartMixed}
				text='Visitor tracking'
				requiredPrivilege='*'
				data-intercom-target='sidebar-link-visitor-tracking'
			/>
			
			<PrivilegesBasedSidebarLink
				to='/email-accounts'
				icon={faAt}
				text='Email accounts'
				requiredPrivilege='EMAIL_ACCOUNTS'
				data-intercom-target='sidebar-link-email-accounts'
			/>
			
			<PrivilegesBasedSidebarLink
				to='/activity-log'
				icon={faClockRotateLeft}
				text='Activity log'
				requiredPrivilege='*'
				data-intercom-target='sidebar-link-activity-log'
			/>
			
			{(websites?.length ?? 0) > 1 && (
				<SidebarLink
					href={website?.url}
					icon={faArrowUpRightFromSquare}
					text='View website'
					disabled={!website?.url}
					tooltip={signedIn ? 'Please choose a community first' : 'Please sign in first'}
					data-intercom-target='sidebar-link-view-website'
				/>
			)}
		</React.Fragment>
	);
};

export default SidebarLinks;
