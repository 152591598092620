// Imports
import React from 'react';
import * as Sentry from '@sentry/browser';


// Component class
//  -> There are no Hooks for error boundary functions yet (https://reactjs.org/docs/error-boundaries.html)
class InvisibleErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
	constructor(props: { children: React.ReactNode }) {
		super(props);
		this.state = { hasError: false };
	}
	
	static getDerivedStateFromError() {
		return { hasError: true };
	}
	
	componentDidCatch(error: Error) {
		// Report to Sentry
		Sentry.captureException(error);
	}
	
	render() {
		if (this.state.hasError) {
			return null;
		}
		
		return this.props.children;
	}
}


// Export
export default InvisibleErrorBoundary;
