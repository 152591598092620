// Imports
import { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useUpdateOnlyEffect from '../../shareables/foundation/front-end/utils/use-update-only-effect';
import { GetWebsitesResponse } from '../../shareables/types/api/main/self/websites/get';
import useCachedState from '../../shareables/foundation/front-end/utils/use-cached-state';
import * as Sentry from '@sentry/browser';
import useRefreshWebsites from '../../hooks/use-refresh-websites';
import keyValueStorage from '../../shareables/foundation/front-end/utils/key-value-storage';


// Function component
const WebsiteFunctionality: React.FC = () => {
	// Get potential website ID URL parameter
	const searchParamsWebsiteID = new URLSearchParams(window.location.search).get('websiteID');
	
	
	// Wipe out cache on first mount if `websiteID` search param is present, and different from the cached website ID
	const checkedForCachedWebsite = useRef(false);
	
	if (!checkedForCachedWebsite.current) {
		// Set flag to true
		checkedForCachedWebsite.current = true;
		
		
		// Check for a cached website
		const cachedWebsite = keyValueStorage.getItem('website');
		
		if (cachedWebsite && searchParamsWebsiteID) {
			// Parse the website
			const parsedWebsite = JSON.parse(cachedWebsite) as GetWebsitesResponse[0] | null;
			
			
			// Check the parsed website ID against the website ID in the search params
			if (!parsedWebsite || parsedWebsite.id !== Number(searchParamsWebsiteID)) {
				// Clear the cache except for the cached websites
				keyValueStorage.clearSelectively(['websites']);
				
				
				// Check for cached websites
				const cachedWebsites = keyValueStorage.getItem('websites');
				
				
				// Add the requested website object to the cache if possible
				if (cachedWebsites) {
					const parsedWebsites = JSON.parse(cachedWebsites) as GetWebsitesResponse | null;
					
					const newWebsite = parsedWebsites?.find((website) => website.id === Number(searchParamsWebsiteID));
					
					if (newWebsite) {
						keyValueStorage.setItem('website', JSON.stringify(newWebsite));
					}
				}
			}
		}
	}
	
	
	// Use React Router functionality
	const navigate = useNavigate();
	const location = useLocation();
	
	
	// Use cached state
	const [website, setWebsite] = useCachedState<GetWebsitesResponse[0] | null>(null, 'website');
	
	
	// Pick out just the website ID
	let websiteID = website?.id;
	
	
	// Clear previously-cached global sign in website on mount
	const checkedForGlobalLogIn = useRef(false);
	
	if (!checkedForGlobalLogIn.current) {
		checkedForGlobalLogIn.current = true;
		
		if (website?.globalLogIn && !searchParamsWebsiteID) {
			setWebsite(null);
			websiteID = undefined;
		}
	}
	
	
	// Determine the requested website ID
	const requestedWebsiteID = useRef(searchParamsWebsiteID ? parseInt(searchParamsWebsiteID) : undefined);
	
	
	// Detect a website selected by the user
	if (websiteID !== requestedWebsiteID.current && !searchParamsWebsiteID) {
		requestedWebsiteID.current = undefined;
	}
	
	
	// Use refresh websites
	const refreshWebsites = useRefreshWebsites(requestedWebsiteID.current);
	
	
	// Refresh websites
	useEffect(() => {
		void refreshWebsites();
	}, [refreshWebsites]);
	
	
	// Store whether we have a previous website ID for comparison
	const hasPreviousWebsiteID = useRef(Number.isInteger(websiteID));
	
	
	// Handle if switching websites
	useUpdateOnlyEffect(() => {
		// Store and assign whether there was a previous website ID to avoid redundancy
		const hadWebsiteIDOnLastUpdate = hasPreviousWebsiteID.current;
		hasPreviousWebsiteID.current = Number.isInteger(websiteID);
		
		
		// Do nothing if no website was selected on the last update
		if (!hadWebsiteIDOnLastUpdate) {
			return;
		}
		
		
		// Do nothing if a specific website ID was attached to the URL
		if (requestedWebsiteID.current) {
			return;
		}
		
		
		// Split pathname into pieces
		const pathnamePieces = location.pathname.split('/');
		
		
		// Grab the section they were in previously
		const section = pathnamePieces[1];
		
		
		// Determine desired path
		const desiredPath = `/${section ?? ''}${pathnamePieces.length > 2 ? '/' : ''}`;
		
		
		// Redirect
		if (location.pathname !== desiredPath) {
			navigate(desiredPath);
		}
	}, [websiteID]);
	
	
	// Handle every time the website changes, including on first mount
	useEffect(() => {
		Sentry.configureScope((scope) => {
			scope.setExtra('website_id', websiteID || null);
		});
	}, [websiteID]);
	
	
	// Retain requested website ID in URL
	//  -> Enables shareability and ensures retention after refreshing
	useEffect(() => {
		if (
			requestedWebsiteID.current &&
			website?.globalLogIn &&
			!location.search.includes(`websiteID=${requestedWebsiteID.current}`)
		) {
			const searchParams = new URLSearchParams(location.search);
			searchParams.set('websiteID', String(requestedWebsiteID.current));
			navigate(`${location.pathname}?${searchParams.toString()}${location.hash}`, {
				replace: true,
			});
		}
	}, [location.pathname, websiteID, requestedWebsiteID.current]); // eslint-disable-line react-hooks/exhaustive-deps
	
	
	// Render nothing
	return null;
};

export default WebsiteFunctionality;
