// Imports
import { z } from 'zod';


// A function that type checks the history state object for a persisted form value
export default function parsePersistentFormValue<T>(
	uniqueIdentifier: string,
	name: string,
	expectedTypeSchema: z.ZodSchema<T>
): T | undefined {
	// This value has to be manually typed (for now)
	//  -> https://github.com/colinhacks/zod/issues/995
	const persistedValue: z.ZodSchema<{
		[key: string]: T;
	}> = z.object({
		[name]: expectedTypeSchema,
	});
	
	
	// Build schema
	const stateSchema = z
		.object({
			state: z
				.object({
					persistentForm: z.object({
						[uniqueIdentifier]: persistedValue,
					}),
				})
				.nullable(),
		})
		.deepPartial()
		.nullable();
	
	
	// Parse
	const state = stateSchema.parse(window.history.state);
	
	
	// Return
	return state?.state?.persistentForm?.[uniqueIdentifier]?.[name];
}
