// Imports
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../shareables/foundation/front-end/redux/hooks';
import { useLocation } from 'react-router-dom';
import adminUIActions from '../../../../../shareables/foundation/admin-ui/redux/actions';


// Function component
const AutoHideSidebar: React.FC = () => {
	// Use React Router functionality
	const location = useLocation();
	
	
	// Use Redux functionality
	const dispatch = useAppDispatch();
	const sidebarIsVisible = useAppSelector((state) => state.adminUI.sidebar.isVisible);
	
	
	// Listen to route changes
	useEffect(() => {
		if (sidebarIsVisible) {
			dispatch(adminUIActions.hideSidebar());
		}
	}, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps
	
	
	// Render nothing
	return null;
};

export default AutoHideSidebar;
