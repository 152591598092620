// Imports
import styled from 'styled-components/macro';
import { setLightness, setSaturation } from 'polished';
import theme from '../../../../../shareables/theme';


// Styled components
const NotificationCount = styled.span`
	padding: 0 0.2rem;
	min-width: 1rem;
	height: 1rem;
	border-radius: 9999rem;
	background: ${setSaturation(0.76, setLightness(0.59, theme.colors.danger))};
	margin-left: 0.4rem;
	font-size: 0.65rem;
	line-height: 1;
	font-weight: 500;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export default NotificationCount;
