// Imports
import React from 'react';
import styled, { css } from 'styled-components/macro';
import theme from '../../../../theme';
import { parseToHsl, setLightness, setSaturation, readableColor } from 'polished';
import uniqBy from 'lodash/uniqBy';


// Build color spectrum
const initialColors = uniqBy(
	[theme.colors.primary, theme.colors.success, theme.colors.danger, theme.colors.info],
	(color) => Math.round(parseToHsl(color).hue)
);

const saturationAndLightnessChoices: { saturation: number; lightness: number }[] = [
	{
		saturation: 0.6,
		lightness: 0.5,
	},
	{
		saturation: 0.7,
		lightness: 0.4,
	},
	{
		saturation: 0.8,
		lightness: 0.3,
	},
	{
		saturation: 0.9,
		lightness: 0.2,
	},
];

const possibleColors: string[] = [];

initialColors.forEach((color) => {
	saturationAndLightnessChoices.forEach(({ saturation, lightness }) => {
		possibleColors.push(setLightness(lightness, setSaturation(saturation, color)));
	});
});


// Styled components
interface WrapperProps {
	round?: boolean;
	backgroundColor: string;
}

const Wrapper = styled.span<WrapperProps>`
	color: ${({ backgroundColor }) => readableColor(backgroundColor)};
	background-color: ${({ backgroundColor }) => backgroundColor};
	
	${({ round }) =>
		round
			? css`
					border-radius: 50%;
			  `
			: ''}
`;


// Define the accepted props
interface Props extends Omit<WrapperProps, 'backgroundColor'> {
	firstName?: string;
	lastName?: string;
}

type CompleteProps = Props &
	Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'children' | 'ref'>;


// Function component
const UserAvatar: React.FC<CompleteProps> = ({ firstName, lastName, ...props }) => {
	// Generate a background color from bytes of provided name
	const possibleColorIndex =
		new TextEncoder()
			.encode(`${firstName ?? ''}${lastName ? ` ${lastName}` : ''}`)
			.reduce((acc, byte) => (acc += byte), 0) % possibleColors.length;
	
	const backgroundColor = firstName ? possibleColors[possibleColorIndex] : '#ddd';
	
	
	// Return JSX
	return (
		<Wrapper backgroundColor={backgroundColor} {...props}>
			{`${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`.toUpperCase()}
		</Wrapper>
	);
};

export default UserAvatar;
