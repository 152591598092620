// Export initial state interface
export interface State {
	sidebar: {
		isVisible: boolean;
	};
	newVersionIsAvailable: boolean;
	pwa: {
		active: boolean;
	};
}


// Define types/interfaces for actions
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export interface ToggleSidebarAction {
	type: typeof TOGGLE_SIDEBAR;
}

export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
export interface HideSidebarAction {
	type: typeof HIDE_SIDEBAR;
}

export const PWA_ACTIVE = 'PWA_ACTIVE';
export interface PWAActiveAction {
	type: typeof PWA_ACTIVE;
}

export const NEW_VERSION_IS_AVAILABLE = 'NEW_VERSION_IS_AVAILABLE';
export interface NewVersionIsAvailableAction {
	type: typeof NEW_VERSION_IS_AVAILABLE;
}


// Export union type
export type Actions = ToggleSidebarAction | HideSidebarAction | PWAActiveAction | NewVersionIsAvailableAction;
