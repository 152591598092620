// Imports
import { State, STORE_PENDING_ACCOUNTS, Actions } from './types';


// Define initial state
const initialState: State = {
	pendingAccounts: [],
};


// The root reducer
export default function reducer(state = initialState, action: Actions): State {
	switch (action.type) {
		case STORE_PENDING_ACCOUNTS:
			return {
				...state,
				pendingAccounts: action.accounts,
			};
		default:
			return state;
	}
}
