// Imports
import styled from 'styled-components/macro';
import { setLightness, setSaturation } from 'polished';
import theme from '../../../../../shareables/theme';


// Styled component
const Divider = styled.hr`
	width: calc(100% - 40px);
	height: 1px;
	min-height: 1px;
	margin: 1.25rem auto;
	background: ${setSaturation(0.25, setLightness(0.2, theme.colors.primary))};
	border: none;
	display: block;
`;

export default Divider;
