// Imports
import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';
import uniqueId from 'lodash/uniqueId';
import { ThemeProvider } from 'styled-components/macro';
import helperFunctions from '../../../../../../shareables/foundation/front-end/utils/helper-functions';
import useUpdateOnlyEffect from '../../../../../../shareables/foundation/front-end/utils/use-update-only-effect';
import saveValue from './save-value';
import FieldContainer from '../../../../../../shareables/foundation/front-end/components/forms/field-container';
import { Label } from '../../../../../../shareables/foundation/front-end/components/forms/labels';
import ControlGroup from '../../../../../../shareables/foundation/front-end/components/forms/control-group';
import AddOn from '../../../../../../shareables/foundation/front-end/components/forms/add-on';
import InputContainer from '../../../../../../shareables/foundation/front-end/components/forms/input-container';
import InputIconHolder from '../../../../../../shareables/foundation/front-end/components/forms/input-icon-holder';
import FieldErrorMessage from '../../../../../../shareables/foundation/front-end/components/forms/field-error-message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import APIConnectedFormContext, { ContextDetails } from '../../../../../contexts/api-connected-form';
import debounce from 'lodash/debounce';
import { z } from 'zod';
import parsePersistentFormValue from './utils/parse-persistent-form-value';


// Define the accepted props
interface Props {
	name: string;
	initialValue?: string | number;
	icon?: IconDefinition;
	label?: string;
	note?: string | React.ReactElement;
	prefix?: string;
	suffix?: string;
	placeholder?: string;
	errorMessage?: string;
	onChange?: (newValue: string | number) => void;
	bindGetter?: (getter: () => string | number) => void;
	bindSetter?: (setter: (value: string | number) => void) => void;
	recoverState?: {
		enabled: boolean;
		uniqueIdentifier: string;
	};
	ignoreIconWarning?: true;
	exclude?: boolean;
}

type CompleteProps = Props &
	Omit<
		React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
		'id' | 'className' | 'value' | 'defaultValue' | 'checked' | 'defaultChecked' | 'prefix' | 'placeholder' | 'onChange'
	>;


// Function component
const Select = React.forwardRef<HTMLSelectElement, CompleteProps>((props: CompleteProps, ref) => {
	// Break off the props we don't want to give to the intrinsic element
	const {
		initialValue,
		icon,
		label,
		note,
		prefix,
		suffix,
		placeholder,
		errorMessage: errorMessageProp,
		onChange: onChangeProp,
		bindGetter,
		bindSetter,
		recoverState: recoverStateProp,
		ignoreIconWarning,
		exclude: excludeProp,
		children: selectOptions,
		...passThroughProps
	} = props;
	
	
	// Use context
	const context = useContext(APIConnectedFormContext);
	
	
	// Retrieve values from API form's context, falling back to props
	const recoverState = recoverStateProp ?? context?.recoverState;
	const errorMessage = context?.error.field === passThroughProps.name ? context.error.message : errorMessageProp;
	
	
	// Decide if the field is excluded
	const exclude = excludeProp ?? passThroughProps.disabled ?? undefined;
	
	
	// Prevent problematic usages that can't be prevented with TypeScript
	if (props.icon && props.icon.prefix !== 'fas' && props.icon.prefix !== 'fab' && !ignoreIconWarning) {
		throw new Error('An field’s icon should come from Font Awesome’s "solid" or "brand" package');
	}
	
	
	// Function that gets all option values
	const getOptionValues = () => {
		// Build option values
		const values: (string | number)[] = [];
		
		helperFunctions.recursivelyIterate(selectOptions, (selectChild) => {
			// Skip non-option elements
			if (selectChild.type !== 'option') {
				return selectChild;
			}
			
			
			// Store value of option
			const { value, children: textContent } = selectChild.props as Record<string, unknown>;
			
			if (typeof value === 'string' || typeof value === 'number') {
				// Use the option's explicit value prop
				values.push(value);
			} else {
				// Use the option's text content
				values.push(
					textContent && Array.isArray(textContent) && typeof textContent[0] === 'string' ? textContent[0] : ''
				);
			}
			
			
			// Return child
			return selectChild;
		});
		
		
		// Return
		return values;
	};
	
	
	// Only perform initial value check on first render
	const hasPerformedInitialValueCheck = useRef(false);
	
	if (!hasPerformedInitialValueCheck.current) {
		// Get possible initial values
		const possibleInitialValues = getOptionValues();
		
		
		// Validate initial value
		if (initialValue !== undefined && !possibleInitialValues.includes(initialValue)) {
			console.error('Type:', typeof initialValue);
			console.error('Possible:', possibleInitialValues.join(', '));
			throw new Error(`The initial value (${initialValue}) for “${props.name}” is invalid`);
		}
		
		
		// Note that we've performed the check
		hasPerformedInitialValueCheck.current = true;
	}
	
	
	// Deduce initial value
	let deducedInitialValue: string | number | undefined = undefined;
	
	if (initialValue === undefined) {
		let count = 0;
		
		helperFunctions.recursivelyIterate(selectOptions, (selectChild) => {
			// Skip non-option elements
			if (selectChild.type !== 'option') {
				return selectChild;
			}
			
			
			// Store value of option
			if (count === 0) {
				const { value, children: textContent } = selectChild.props as Record<string, unknown>;
				
				if (typeof value === 'string' || typeof value === 'number') {
					// Use the option's explicit value prop
					deducedInitialValue = value;
				} else {
					// Use the option's text content
					deducedInitialValue =
						textContent && Array.isArray(textContent) && typeof textContent[0] === 'string' ? textContent[0] : '';
				}
			}
			
			
			// Increment count
			count++;
			
			
			// Return child
			return selectChild;
		});
	}
	
	
	// Use state
	const [value, setValue] = useState<string | number>(
		initialValue !== undefined ? initialValue : placeholder !== undefined ? '' : deducedInitialValue || ''
	);
	
	
	// Bind getter/setter
	bindGetter && bindGetter(() => value);
	bindSetter && bindSetter(setValue);
	
	
	// Add and remove getter from connected form
	const { name } = passThroughProps;
	let addValueGetter: NonNullable<ContextDetails>['addValueGetter'] | null = null;
	let removeValueGetter: NonNullable<ContextDetails>['removeValueGetter'] | null = null;
	
	if (context) {
		addValueGetter = context.addValueGetter;
		removeValueGetter = context.removeValueGetter;
	}
	
	useEffect(() => {
		if (addValueGetter === null || exclude === true) {
			return;
		}
		
		addValueGetter(name, () => value);
		
		return () => {
			if (removeValueGetter === null) {
				return;
			}
			
			removeValueGetter(name);
		};
	}, [name, value, addValueGetter, removeValueGetter, exclude]);
	
	
	// Generate unique ID
	const uniqueID = useRef(uniqueId('select-field-'));
	
	
	// Handle value changes
	const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
		setValue(event.target.value);
	};
	
	
	// Debounce save value function
	const debouncedSaveValue = useCallback(debounce(saveValue, 300), []); // eslint-disable-line react-hooks/exhaustive-deps
	
	
	// Save new value on updates
	useUpdateOnlyEffect(() => {
		recoverState &&
			recoverState.enabled &&
			debouncedSaveValue(passThroughProps.name, String(value), recoverState.uniqueIdentifier);
	}, [value]);
	
	
	// Notify parent of changes
	useUpdateOnlyEffect(() => {
		onChangeProp?.(value);
		context?.onChange?.();
	}, [value]);
	
	useUpdateOnlyEffect(() => {
		context?.onExcludeChange?.();
	}, [exclude]);
	
	
	// Use ref
	const hasMounted = useRef(false);
	
	
	// Attempt to recover state on mount
	if (recoverState && recoverState.enabled && hasMounted.current === false) {
		hasMounted.current = true;
		
		window.setTimeout(() => {
			const recoveredValue = parsePersistentFormValue(
				recoverState.uniqueIdentifier,
				passThroughProps.name,
				z.union([z.string(), z.number()])
			);
			
			if (typeof recoveredValue !== 'undefined' && getOptionValues().includes(recoveredValue)) {
				setValue(recoveredValue);
			}
		}, 1); // Must happen asynchronously
		
		/* Don't trigger onChange here; it will be triggered before all getters are bound, and cause issues */
	}
	
	
	// Create theme
	const theme = {
		hasError: Boolean(errorMessage),
	};
	
	
	// Return JSX
	return (
		<ThemeProvider theme={theme}>
			<FieldContainer>
				{label && (
					<Label htmlFor={uniqueID.current}>
						{label}
						{note && <small>{note}</small>}
					</Label>
				)}
				
				<ControlGroup>
					{prefix && <AddOn>{prefix}</AddOn>}
					
					<InputContainer>
						{icon && (
							<InputIconHolder>
								<FontAwesomeIcon icon={icon} />
							</InputIconHolder>
						)}
						
						<select
							{...passThroughProps}
							id={uniqueID.current}
							value={value}
							onChange={handleChange}
							ref={ref}
							aria-invalid={Boolean(errorMessage)}
							aria-describedby={`error-field-${uniqueID.current}`}
						>
							{placeholder !== undefined && (
								<option key='placeholder' value='' disabled>
									{placeholder}
								</option>
							)}
							{selectOptions}
						</select>
						
						<InputIconHolder color={passThroughProps.disabled ? undefined : '#555'} right>
							<FontAwesomeIcon icon={faChevronDown} />
						</InputIconHolder>
					</InputContainer>
					
					{suffix && <AddOn>{suffix}</AddOn>}
				</ControlGroup>
				
				{errorMessage && <FieldErrorMessage id={`error-field-${uniqueID.current}`} message={errorMessage} />}
			</FieldContainer>
		</ThemeProvider>
	);
});


// @ts-ignore: displayName does exist
Select.displayName = 'Select';

export default Select;
