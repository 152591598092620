// Imports
import React from 'react';
import Heading from '../../../../../shareables/foundation/admin-ui/components/content/heading';
import Box from '../../../../../shareables/foundation/admin-ui/components/content/box';


// Function component
const Container: React.FC = () => {
	// Return JSX
	return (
		<React.Fragment>
			<Heading size={1}>Not found</Heading>
			
			<Box>We couldn’t find the page you were looking for. Try using the links on the left to get back on track.</Box>
		</React.Fragment>
	);
};

const NotFound = React.memo(Container);

export default NotFound;
