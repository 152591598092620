// Imports
import React from 'react';
import keyValueStorage from '../../shareables/foundation/front-end/utils/key-value-storage';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import useCachedState from '../../shareables/foundation/front-end/utils/use-cached-state';
import { GetWebsitesResponse } from '../../shareables/types/api/main/self/websites/get';
import { useNavigate } from 'react-router-dom';
import { websiteSchema } from '../pages/select-website';


// Styled components
const Container = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	align-content: center;
	justify-content: center;
	line-height: normal;
	
	select {
		width: 90%;
		height: 100%;
		opacity: 0;
		position: absolute;
		left: 5%;
		cursor: pointer;
		background-color: inherit;
		color: transparent;
		border: none;
		transition: outline-offset 0.2s ease;
		
		&:focus-visible {
			opacity: 1;
			outline-offset: 1px;
		}
		
		> option:not(:disabled) {
			color: initial;
		}
	}
`;

const For = styled.span`
	padding-left: 0.2em;
`;

const TaglineLink = styled.a`
	color: inherit;
	transition: color 0.2s ease;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	
	&:hover {
		color: #fff;
	}
`;

const CommunityNamePiece = styled.span`
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const LastCommunityNamePiece = styled.span`
	max-width: calc(100% - 1.132em);
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: 0.382em;
	display: inline-block;
	vertical-align: bottom;
`;

const CommunityName = styled.span`
	max-width: calc(75% - 0.875em);
	margin-right: 0.25em;
	overflow: hidden;
	text-overflow: ellipsis;
`;


// Define the accepted props
interface Props {
	storybookWebsite?: Pick<GetWebsitesResponse[0], 'communityName' | 'url'>;
	storybookWebsites?: Pick<GetWebsitesResponse[0], 'id' | 'communityName'>[];
}


// Function component
const Tagline: React.FC<Props> = ({ storybookWebsite, storybookWebsites }) => {
	// Use React Router functionality
	const navigate = useNavigate();
	
	
	// Use state
	const [website, setWebsite] = useCachedState<typeof storybookWebsite | null>(storybookWebsite ?? null, 'website');
	const [websites] = useCachedState<typeof storybookWebsites | null>(storybookWebsites ?? null, 'websites');
	
	
	// Determine tagline
	const communityNamePieces = website?.communityName.split(' ');
	const tagline =
		website && communityNamePieces ? (
			<React.Fragment>
				{(websites && websites.length > 1) || (websites?.length === 1 && communityNamePieces.length > 1) ? (
					<React.Fragment>
						<CommunityNamePiece>
							<For>for</For> {communityNamePieces[0]}
						</CommunityNamePiece>
						{communityNamePieces.length > 1 ? ' ' : ''}
					</React.Fragment>
				) : (
					<For>for </For>
				)}
				{communityNamePieces
					.slice(1, websites?.length === 1 ? communityNamePieces.length - 1 : communityNamePieces.length)
					.join(' ')}
				{websites?.length === 1 && communityNamePieces.length > 2 ? ' ' : ''}
			</React.Fragment>
		) : null;
	
	
	// Handle select change
	const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
		// Parse value
		const currentSelection = websiteSchema.parse(JSON.parse(event.target.value));
		
		
		// Clear local storage
		keyValueStorage.clearSelectively(['websites']);
		
		
		// Remove `websiteID` search param
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.delete('websiteID');
		const searchParamsString = searchParams.toString();
		
		navigate(
			`${window.location.pathname}${searchParamsString ? `?${searchParamsString}` : ''}${window.location.hash}`,
			{ replace: true }
		);
		
		
		// Store website data in local storage
		setWebsite(currentSelection);
	};
	
	
	// Return JSX
	return (
		<Container>
			{websites && communityNamePieces && websites.length === 1 && (
				<TaglineLink href={website?.url} target='_blank' rel='noopener noreferrer'>
					{communityNamePieces.length > 1 && tagline}
					<CommunityNamePiece>
						<LastCommunityNamePiece>
							{communityNamePieces.length === 1 && tagline}
							{communityNamePieces[communityNamePieces.length - 1]}
						</LastCommunityNamePiece>
						<FontAwesomeIcon icon={faArrowUpRightFromSquare} size='xs' />
					</CommunityNamePiece>
				</TaglineLink>
			)}
			
			{websites && website && websites.length > 1 && (
				<React.Fragment>
					<CommunityName>{tagline}</CommunityName>
					
					<FontAwesomeIcon icon={faAngleDown} />
					<select
						onChange={handleSelectChange}
						value={website ? JSON.stringify(website) : 'NONE'}
						aria-label='select community…'
					>
						<option disabled value='NONE'>
							select community…
						</option>
						
						{websites &&
							websites.map((website) => {
								return (
									<option key={website.id} value={JSON.stringify(website)}>
										{website.communityName}
									</option>
								);
							})}
					</select>
				</React.Fragment>
			)}
			
			{websites && !website && websites.length > 1 && 'please select community…'}
			
			{websites && !website && websites.length <= 1 && 'please select community…'}
			
			{!websites && 'welcome, please sign in…'}
		</Container>
	);
};

export default Tagline;
