// Imports
import merge from 'lodash/merge';


// Save a value into the browser's history state
const saveValue = (name: string, value: string | boolean, uniqueIdentifier: string) => {
	window.history.replaceState(
		merge(window.history.state || {}, {
			state: {
				persistentForm: {
					[uniqueIdentifier]: {
						[name]: value,
					},
				},
			},
		}),
		''
	);
};

export default saveValue;
