// Imports
import React, { useState, useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../../../front-end/redux/hooks';
import adminUIActions from '../../../../../shareables/foundation/admin-ui/redux/actions';
import styled, { css } from 'styled-components/macro';
import { setLightness, setSaturation } from 'polished';
import theme from '../../../../../shareables/theme';
import Aside from './aside';


// Styled components
interface ContainerProps {
	visible: boolean;
}

const Container = styled(Aside)<ContainerProps>`
	background: ${setSaturation(0.55, setLightness(0.55, theme.colors.success))};
	border-color: ${setSaturation(0.55, setLightness(0.45, theme.colors.success))};
	visibility: hidden;
	opacity: 0;
	transition: opacity 2s ease;
	
	button {
		padding: 0 !important;
		background: none !important;
		border: none;
		color: inherit;
		text-decoration: underline;
		font: inherit;
		cursor: pointer;
	}
	
	${(props) =>
		props.visible
			? css`
					visibility: visible;
					opacity: 1;
			  `
			: ''}
`;


// Determine check interval
const checkInterval = 1000 * 60 * 5;


// Define the accepted props
interface Props {
	appName: string;
}


// Function component
const NewVersionNotice: React.FC<Props> = ({ appName }) => {
	// Use Redux functionality
	const dispatch = useAppDispatch();
	const pwaActive = useAppSelector((state) => state.adminUI.pwa.active);
	const newVersionIsAvailable = useAppSelector((state) => state.adminUI.newVersionIsAvailable);
	
	
	// Use state
	const [previousHTML, setPreviousHTML] = useState<string | null>(null);
	
	
	// Use ref for the timer
	const timerRef = useRef<number>();
	
	
	// Add/remove timer
	useEffect(() => {
		// Checks for new version
		const checkForNewVersion = () => {
			// Do nothing if PWA is active
			if (pwaActive) {
				return;
			}
			
			
			// Otherwise, fetch the `index.html` file
			fetch('/index.html')
				.then((response) => {
					if (response.status !== 200) {
						timerRef.current = window.setTimeout(() => {
							checkForNewVersion();
						}, checkInterval);
						
						return;
					}
					
					return response.text();
				})
				.then((latestHTML) => {
					// Type guard
					if (!latestHTML) {
						return;
					}
					
					
					// Remove Cloudflare beacon script (which is only added a small percentage of requests)
					latestHTML = latestHTML.replace(
						/<script [^>]+src=["']https:\/\/static\.cloudflareinsights\.com[^>]+?["'][^>]*?>.*?<\/script>/g,
						''
					);
					
					
					// Remove all whitespace, to help avoid false positives
					latestHTML = latestHTML.replace(/\s/g, '');
					
					
					// Check if the new HTML is different
					if (previousHTML && previousHTML !== latestHTML) {
						dispatch(adminUIActions.newVersionIsAvailable());
						return;
					}
					
					
					// Store this HTML for next check
					setPreviousHTML(latestHTML);
					
					
					// Check again in a bit
					timerRef.current = window.setTimeout(() => {
						checkForNewVersion();
					}, checkInterval);
				})
				.catch(() => {
					// Ignore and restart timer
					timerRef.current = window.setTimeout(() => {
						checkForNewVersion();
					}, checkInterval);
				});
		};
		
		
		// Handle if new version is not available
		if (!newVersionIsAvailable) {
			timerRef.current = window.setTimeout(() => {
				checkForNewVersion();
			}, checkInterval);
		}
		
		
		// Return deconstructor
		return () => clearTimeout(timerRef.current);
	}, [newVersionIsAvailable, previousHTML, dispatch, pwaActive]);
	
	
	// Return JSX
	return pwaActive ? (
		<Container visible={newVersionIsAvailable}>
			We’ve made updates! Close any open {appName} tabs and reopen them for the latest improvements.
		</Container>
	) : (
		<Container visible={newVersionIsAvailable}>
			We’ve made updates! <button onClick={() => window.location.reload()}>Refresh</button> for the latest improvements.
		</Container>
	);
};

export default NewVersionNotice;
