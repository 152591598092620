// Imports
import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { setLightness, setSaturation } from 'polished';
import theme from '../../../../../shareables/theme';
import { useAppSelector } from '../../../front-end/redux/hooks';


// Styled components
const moveIndeterminate = keyframes`
    0% {
        background-position: 200% 0;
    }
    
    100% {
        background-position: -200% 0;
    }
`;

const Container = styled.div`
	position: fixed;
	top: 0;
	z-index: 9999;
	
	height: 0.2rem;
	width: 100%;
	background-color: transparent;
`;

const Progress = styled.div`
	width: 100%;
	height: 0.2rem;
	margin: 0;
	padding: 0;
	appearance: none;
	border: none;
	display: block;
	overflow: hidden;
	border-radius: 0;
	background-color: transparent;
	background-image: linear-gradient(
		90deg,
		${setSaturation(0.38, setLightness(0.53, theme.colors.success))} 30%,
		transparent 0
	);
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-name: ${moveIndeterminate};
	animation-timing-function: linear;
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 150% 150%;
`;


// Define the accepted props
interface Props {
	storybookActiveNetworkRequests?: number;
}


// Function component
const NetworkRequestLoadingIndicator: React.FC<Props> = ({ storybookActiveNetworkRequests }) => {
	// Use Redux functionality
	const activeNetworkRequestCount = useAppSelector(
		(state) => storybookActiveNetworkRequests ?? state.common.activeNetworkRequestCount
	);
	
	
	// Return JSX
	return <Container>{activeNetworkRequestCount > 0 && <Progress></Progress>}</Container>;
};

export default NetworkRequestLoadingIndicator;
