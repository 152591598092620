// Imports
import { combineReducers } from 'redux';
import adminUIReducer from '../shareables/foundation/admin-ui/redux/reducer';
import apiReducer from '../shareables/integrations/api/redux/reducer';
import commonReducer from '../shareables/foundation/front-end/redux/reducer';
import selfReducer from './self/reducer';


// The root reducer
export default combineReducers({
	adminUI: adminUIReducer,
	api: apiReducer,
	common: commonReducer,
	self: selfReducer,
});
