// Imports
import React, { useCallback } from 'react';
import { useAppDispatch } from '../../../front-end/redux/hooks';
import adminUIActions from '../../../../../shareables/foundation/admin-ui/redux/actions';
import { useLocation } from 'react-router-dom';
import keyValueStorage from '../../../../../shareables/foundation/front-end/utils/key-value-storage';
import Breadcrumbs from './breadcrumbs';
import SupportOptions from './support-options';
import UserDropdown, { ExtraUserDropdownItem } from './user-dropdown';
import styled from 'styled-components/macro';
import { setLightness, setSaturation } from 'polished';
import theme from '../../../../../shareables/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';


// Styled components
const Container = styled.div`
	margin: 0;
	padding: 1rem 1.5rem;
	background: ${setSaturation(0.25, setLightness(0.32, theme.colors.primary))};
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: normal;
	flex-direction: row;
	position: relative;
	
	@media (max-width: ${theme.breakpoints.mobile}px) {
		justify-content: space-between;
	}
`;

const SidebarToggle = styled.button`
	height: 1.75rem;
	border-radius: 3px;
	background: ${setSaturation(0.25, setLightness(0.24, theme.colors.primary))};
	padding: 0rem 0.5rem;
	border: 0;
	
	> svg.fa-bars {
		width: 0.875rem;
		height: 1.75rem;
		color: #fff;
	}
	
	@media (min-width: ${theme.breakpoints.mobile + 1}px) {
		display: none;
	}
`;


// Define the accepted props
interface Props {
	disableSupportOptions?: true;
	extraUserDropdownItems?: ExtraUserDropdownItem[];
	storybookSupportNumber?: string;
	storybookProductionDomain?: string;
}


// Function component
const TopBar: React.FC<Props> = ({
	disableSupportOptions,
	extraUserDropdownItems,
	storybookSupportNumber,
	storybookProductionDomain,
}) => {
	// Use React Router functionality
	const location = useLocation();
	
	
	// Use Redux functionality
	const dispatch = useAppDispatch();
	
	
	// Toggle sidebar
	const toggleSidebar = () => {
		dispatch(adminUIActions.toggleSidebar());
	};
	
	
	// Sign out function
	const memoizedSignOut = useCallback(() => {
		// Clear local storage
		keyValueStorage.clearSelectively();
		
		
		// Shutdown Intercom
		window.Intercom?.('shutdown');
		
		
		// Redirect
		window.location.href = `https://auth.${
			process.env.REACT_APP__ENVIRONMENT_HOSTNAME
		}/sign-out?return=${encodeURIComponent(window.location.href)}`;
	}, []);
	
	
	// Return JSX
	return (
		<React.Fragment>
			<Container>
				<SidebarToggle onClick={toggleSidebar} aria-label='Toggle sidebar'>
					<FontAwesomeIcon icon={faBars} />
				</SidebarToggle>
				
				<Breadcrumbs fullURI={location.pathname} />
				
				{!disableSupportOptions && (
					<SupportOptions
						storybookSupportNumber={storybookSupportNumber}
						storybookProductionDomain={storybookProductionDomain}
					/>
				)}
				
				<UserDropdown signOut={memoizedSignOut} extraUserDropdownItems={extraUserDropdownItems} />
			</Container>
		</React.Fragment>
	);
};

export default TopBar;
