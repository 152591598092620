// Imports
import React from 'react';
import styled from 'styled-components/macro';


// Styled components
const inFocusTopOffset = '20px';

interface LinkProps {
	focused?: boolean;
}

const Link = styled.a<LinkProps>`
	padding: 10px 20px;
	background: #fff;
	border: 0;
	position: fixed;
	top: ${(props) => (props.focused ? inFocusTopOffset : '-9999px')};
	left: 20px;
	z-index: 9999;
	color: #000;
	font-size: 1rem;
	line-height: 1;
	text-decoration: underline;
	
	&:focus {
		top: ${inFocusTopOffset};
	}
`;


// Define the accepted props
interface Props {
	focused?: boolean;
}


// Function component
const SkipToMainContent: React.FC<Props> = ({ focused }) => {
	return (
		<Link href='#main-content' focused={focused}>
			Skip to main content &rarr;
		</Link>
	);
};

export default SkipToMainContent;
