// Imports
import styled from 'styled-components/macro';
import theme from '../../../../../shareables/theme';
import parseMarkdown from '../../../../../shareables/utils/markdown/parse';


// Styled components
const Wrapper = styled.div`
	color: ${theme.colors.danger};
	font-size: 90%;
	margin-top: 0.1em;
	
	a {
		color: inherit;
		text-decoration: underline;
		
		&:hover {
			color: inherit;
			text-decoration: none;
		}
	}
`;


// Define the accepted props
interface Props
	extends Omit<
		React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
		'children' | 'dangerouslySetInnerHTML' | 'ref'
	> {
	message: string;
}


// An item that appears separate from, and next to, the input itself (prefix, suffix, etc.)
const FieldErrorMessage: React.FC<Props> = ({ message, ...props }) => {
	const errorMessageHTML = parseMarkdown(message);
	
	return (
		<Wrapper
			dangerouslySetInnerHTML={{
				__html: errorMessageHTML,
			}}
			{...props}
		/>
	);
};

export default FieldErrorMessage;
