// Imports
import {
	ToggleSidebarAction,
	HideSidebarAction,
	PWAActiveAction,
	NewVersionIsAvailableAction,
	TOGGLE_SIDEBAR,
	HIDE_SIDEBAR,
	PWA_ACTIVE,
	NEW_VERSION_IS_AVAILABLE,
} from './types';


// Exports
const adminUIActions = {
	// Handles toggling the sidebar on mobile
	toggleSidebar(): ToggleSidebarAction {
		return {
			type: TOGGLE_SIDEBAR,
		};
	},
	
	
	// Handles hiding the sidebar on mobile
	hideSidebar(): HideSidebarAction {
		return {
			type: HIDE_SIDEBAR,
		};
	},
	
	
	// Indicates app is running as a PWA
	pwaActive(): PWAActiveAction {
		return {
			type: PWA_ACTIVE,
		};
	},
	
	
	// Indicates a new version of the app is available
	newVersionIsAvailable(): NewVersionIsAvailableAction {
		return {
			type: NEW_VERSION_IS_AVAILABLE,
		};
	},
};

export default adminUIActions;
