// Imports
import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components/macro';
import NewVersionNotice from './new-version-notice';
import StatuspageSummary from './statuspage-summary';
import ScreenSharingIndicator from './screen-sharing-indicator';
import Divider from './divider';
import { setLightness, setSaturation } from 'polished';
import theme from '../../../../../shareables/theme';
import company from '../../../../company';


// Global style
const GlobalStyle = createGlobalStyle`
    .Canny_BadgeContainer .Canny_Badge {
        top: -0.15rem;
        right: -0.6rem;
        padding: 4px;
        background-color: ${setSaturation(0.61, setLightness(0.54, theme.colors.danger))};
        border: 0;
    }
`;


// Styled components
const Container = styled.footer`
	padding: 1.25rem 0;
	text-align: center;
	position: relative;
`;

const Links = styled.div`
	padding-bottom: 0.5rem;
	font-size: 0.75rem;
	color: #fff;
`;

const Link = styled.a`
	color: #fff !important;
	cursor: pointer;
	text-decoration: none;
	
	&:hover {
		text-decoration: underline;
	}
`;

const Separator = styled.span`
	margin: 0 0.5rem;
	cursor: default;
`;

const Copyright = styled.div`
	font-size: 0.625rem;
	color: ${setSaturation(0.18, setLightness(0.5, theme.colors.primary))};
`;


// Let TypeScript know about third party tools
declare global {
	function Canny(
		command: 'initChangelog',
		options?: {
			appID: string;
			position: string;
			align: string;
		}
	): void;
	
	interface Window {
		Canny?: typeof Canny;
	}
}


// Define the accepted props
interface Props {
	name: string;
	utmSource?: string;
	cannyAppID?: string;
	statuspageAppID?: string;
	storybookProductionDomain?: string;
}


// Function component
const SidebarFooter: React.FC<Props> = ({
	name,
	cannyAppID,
	statuspageAppID,
	utmSource,
	storybookProductionDomain,
}) => {
	// Initialize Canny
	useEffect(() => {
		// Initialize Canny
		if (window.Canny && cannyAppID) {
			window.Canny('initChangelog', {
				appID: cannyAppID,
				position: 'top',
				align: 'left',
			});
		}
		
		
		// NOTE: There does not appear to be a way to destroy Canny
	}, [cannyAppID]);
	
	
	// Handle incorrect usage
	if (Boolean(window.Canny) && !company.productionDomain) {
		throw new Error('Canny used without production domain being defined');
	}
	
	
	// Return JSX
	return (
		<Container role='contentinfo'>
			<GlobalStyle />
			<NewVersionNotice appName={name} />
			<ScreenSharingIndicator />
			{statuspageAppID && (
				<StatuspageSummary appID={statuspageAppID} storybookProductionDomain={storybookProductionDomain} />
			)}
			
			{Boolean(window.Canny) && company.productionDomain && (
				<React.Fragment>
					<Divider />
					
					<Links>
						<Link
							href={`https://feedback.${company.productionDomain}/feature-requests${
								utmSource ? `?utm_source=${utmSource}` : ''
							}`}
							target='_blank'
							rel='noopener noreferrer'
						>
							Ideas &amp; feedback
						</Link>
						
						<Separator>|</Separator>
						
						<Link as='span' data-canny-changelog>
							Changelog
						</Link>
					</Links>
				</React.Fragment>
			)}
			
			<Copyright>&copy; {company.name}, Inc.</Copyright>
		</Container>
	);
};

export default SidebarFooter;
