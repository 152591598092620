// Imports
import styled from 'styled-components/macro';


// The standard label for a form field
export const Label = styled.label`
	width: 100%;
	padding-bottom: 0.3em;
	font-weight: 600;
	display: block;
	color: #363636;
	line-height: 1;
	margin-bottom: 0.2rem;
	
	small {
		font-weight: 400;
		margin-left: 0.75em;
		font-style: italic;
		font-size: 75%;
	}
`;


// A modified label for a checkbox or radio button
export const InlineLabel = styled(Label)`
	font-weight: 400;
	line-height: 1.25;
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	padding-bottom: 0;
	display: flex;
	flex-wrap: nowrap !important;
	align-items: center;
`;
