// Imports
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { setLightness, setSaturation, getContrast } from 'polished';
import theme from '../../../../../shareables/theme';
import Aside from './aside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { z } from 'zod';
import company from '../../../../company';


// Styled components
const IncidentContainer = styled(Aside)`
	background: ${setSaturation(0.75, setLightness(0.6, theme.colors.danger))};
	border-color: ${setSaturation(0.75, setLightness(0.5, theme.colors.danger))};
	color: ${() =>
		getContrast(theme.colors.danger, '#fff') > 1.9
			? '#fff'
			: setSaturation(0.2, setLightness(0.3, theme.colors.danger))};
`;

const MaintenanceContainer = styled(Aside)`
	background: ${setSaturation(0.55, setLightness(0.7, theme.colors.info))};
	border-color: ${setSaturation(0.55, setLightness(0.6, theme.colors.info))};
	color: ${() =>
		getContrast(theme.colors.info, '#fff') > 1.9 ? '#fff' : setSaturation(0.2, setLightness(0.3, theme.colors.info))};
`;

const ScheduledFor = styled.em`
	margin-top: 0.5rem;
	font-size: 90%;
	display: block;
`;

const DetailsLink = styled.a`
	margin-top: 0.5rem;
	display: block;
	text-decoration: underline;
	color: inherit;
	
	&:hover {
		color: inherit;
		text-decoration: none;
	}
`;

const SummaryText = styled.a`
	display: block;
	font-size: 0.65rem;
	line-height: 1;
	color: #fff !important;
	text-decoration: none;
	
	span.dot {
		display: inline-block;
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 9999rem;
	}
	
	svg {
		transform: scale(0.7);
	}
	
	&.all-operational {
		span.dot {
			background: ${setSaturation(0.54, setLightness(0.56, theme.colors.success))};
		}
	}
	
	&.some-not-operational {
		span.dot {
			background: ${setSaturation(1, setLightness(0.67, theme.colors.yellow))};
		}
	}
`;


// Determine check interval
const checkInterval = 1000 * 60 * 5;


// Define schema for Statuspage summary
const statuspageIncidentStatusSchema = z.union([
	z.literal('investigating'),
	z.literal('identified'),
	z.literal('monitoring'),
	z.literal('resolved'),
	z.literal('in_progress'),
	z.literal('scheduled'),
]);

const statuspageIncidentSchema = z.object({
	created_at: z.string(),
	id: z.string(),
	impact: z.union([z.literal('none'), z.literal('minor'), z.literal('major'), z.literal('critical')]),
	incident_updates: z.array(
		z.object({
			body: z.string(),
			created_at: z.string(),
			display_at: z.string(),
			id: z.string(),
			incident_id: z.string(),
			status: statuspageIncidentStatusSchema,
			updated_at: z.string(),
		})
	),
	monitoring_at: z.string().nullable(),
	name: z.string(),
	page_id: z.string(),
	resolved_at: z.string().nullable(),
	shortlink: z.string(),
	status: statuspageIncidentStatusSchema,
	updated_at: z.string(),
});

const summarySchema = z.object({
	components: z.array(
		z.object({
			created_at: z.string(),
			description: z.string().nullable(),
			id: z.string(),
			name: z.string(),
			page_id: z.string(),
			position: z.number(),
			status: z.union([
				z.literal('operational'),
				z.literal('degraded_performance'),
				z.literal('partial_outage'),
				z.literal('major_outage'),
				z.literal('under_maintenance'),
			]),
			updated_at: z.string(),
		})
	),
	incidents: z.array(statuspageIncidentSchema),
	scheduled_maintenances: z.array(
		statuspageIncidentSchema.extend({
			scheduled_for: z.string(),
			scheduled_until: z.string(),
			impact: z.literal('maintenance'),
		})
	),
});

type StatuspageSummary = z.infer<typeof summarySchema>;


// Define the accepted props
interface Props {
	appID: string;
	storybookProductionDomain?: string;
}


// Function component
const StatuspageSummary: React.FC<Props> = ({ appID, storybookProductionDomain }) => {
	// Use state
	const [components, setComponents] = useState<StatuspageSummary['components']>([]);
	const [incidents, setIncidents] = useState<StatuspageSummary['incidents']>([]);
	const [scheduledMaintenances, setScheduledMaintenances] = useState<StatuspageSummary['scheduled_maintenances']>([]);
	
	
	// Use ref for the timer
	const timerRef = useRef<number>();
	
	
	// Add/remove timer
	useEffect(() => {
		// Retrieve information from Statuspage
		const retrieveInfoFromStatuspage = () => {
			// Fake data during testing
			if (appID === 'testing:operational') {
				setComponents([]);
				setIncidents([]);
				setScheduledMaintenances([]);
			} else if (appID === 'testing:someNotOperational') {
				setComponents([
					{
						status: 'partial_outage',
						created_at: '2020-01-01T22:00:00.000-06:00',
						description: null,
						id: 'a',
						name: 'Example',
						page_id: 'abc123',
						position: 0,
						updated_at: '2020-01-01T22:00:00.000-06:00',
					},
				]);
				
				setIncidents([]);
				setScheduledMaintenances([]);
			} else if (appID === 'testing:oneIncident') {
				setComponents([]);
				setIncidents([
					{
						id: 'a',
						name: 'Something is broken',
						shortlink: 'https://status.sparksuite.com/a',
						created_at: '2020-01-01T22:00:00.000-06:00',
						impact: 'major',
						incident_updates: [],
						monitoring_at: null,
						page_id: 'abc123',
						resolved_at: null,
						status: 'investigating',
						updated_at: '2020-01-01T22:00:00.000-06:00',
					},
				]);
				
				setScheduledMaintenances([]);
			} else if (appID === 'testing:multipleIncidents') {
				setComponents([]);
				setIncidents([
					{
						id: 'a',
						name: 'Something is broken',
						shortlink: 'https://status.sparksuite.com/a',
						created_at: '2020-01-01T22:00:00.000-06:00',
						impact: 'major',
						incident_updates: [],
						monitoring_at: null,
						page_id: 'abc123',
						resolved_at: null,
						status: 'investigating',
						updated_at: '2020-01-01T22:00:00.000-06:00',
					},
					{
						id: 'b',
						name: 'Another thing is broken',
						shortlink: 'https://status.sparksuite.com/b',
						created_at: '2020-01-01T22:00:00.000-06:00',
						impact: 'major',
						incident_updates: [],
						monitoring_at: null,
						page_id: 'abc123',
						resolved_at: null,
						status: 'investigating',
						updated_at: '2020-01-01T22:00:00.000-06:00',
					},
				]);
				
				setScheduledMaintenances([]);
			} else if (appID === 'testing:scheduledMaintenance') {
				setComponents([]);
				setIncidents([]);
				setScheduledMaintenances([
					{
						id: 'a',
						name: 'System maintenance',
						shortlink: 'https://status.sparksuite.com/a',
						scheduled_for: '2020-01-01T22:00:00.000-06:00',
						created_at: '2020-01-01T22:00:00.000-06:00',
						impact: 'maintenance',
						incident_updates: [],
						monitoring_at: null,
						page_id: 'abc123',
						resolved_at: null,
						status: 'investigating',
						updated_at: '2020-01-01T22:00:00.000-06:00',
						scheduled_until: '2020-01-01T22:00:00.000-06:00',
					},
				]);
			}
			
			
			// Otherwise, retrieve real data
			fetch(`https://${appID}.statuspage.io/api/v2/summary.json`)
				.then((response) => {
					if (response.status !== 200) {
						timerRef.current = window.setTimeout(() => {
							retrieveInfoFromStatuspage();
						}, checkInterval);
						
						return;
					}
					
					return response.text();
				})
				.then((json) => {
					// Type guard
					if (!json) {
						return;
					}
					
					
					// Parse JSON
					const summary = summarySchema.parse(JSON.parse(json));
					
					
					// Store status details
					setComponents(summary.components);
					setIncidents(summary.incidents);
					setScheduledMaintenances(summary.scheduled_maintenances);
					
					
					// Start countdown to next check
					timerRef.current = window.setTimeout(() => {
						retrieveInfoFromStatuspage();
					}, checkInterval);
				})
				.catch((error) => {
					// Log error in development environments
					if (process.env.REACT_APP__ENVIRONMENT !== 'production') {
						console.error(error);
					}
					
					
					// Ignore and restart timer
					timerRef.current = window.setTimeout(() => {
						retrieveInfoFromStatuspage();
					}, checkInterval);
				});
		};
		
		
		// Retrieve info immediately
		timerRef.current = window.setTimeout(() => {
			retrieveInfoFromStatuspage();
		}, 0);
		
		
		// Return deconstructor
		return () => clearTimeout(timerRef.current);
	}, [appID]);
	
	
	// Create elements for incidents
	let allOperational = true;
	const incidentElements = [];
	
	if (incidents.length) {
		for (const incident of incidents) {
			incidentElements.push(
				<IncidentContainer key={incident.id}>
					{incident.name}
					<DetailsLink href={incident.shortlink} target='_blank' rel='noopener noreferrer'>
						View full details
					</DetailsLink>
				</IncidentContainer>
			);
		}
	} else {
		for (const component of components) {
			if (component.status !== 'operational' && component.status !== 'under_maintenance') {
				allOperational = false;
				break;
			}
		}
	}
	
	
	// Create elements for scheduled maintenance
	const scheduledMaintenanceElements = [];
	
	if (scheduledMaintenances.length) {
		for (const scheduledMaintenance of scheduledMaintenances) {
			scheduledMaintenanceElements.push(
				<MaintenanceContainer key={scheduledMaintenance.id}>
					<strong>{scheduledMaintenance.name}</strong>
					
					{scheduledMaintenance.status === 'in_progress' ? (
						<ScheduledFor>
							Started
							<br />
							{`${new Date(scheduledMaintenance.scheduled_for).toLocaleDateString(undefined, {
								month: 'long',
								day: 'numeric',
							})} at ${new Date(scheduledMaintenance.scheduled_for).toLocaleTimeString(undefined, {
								hour: 'numeric',
								minute: '2-digit',
							})}`}
						</ScheduledFor>
					) : (
						<ScheduledFor>
							Scheduled for
							<br />
							{`${new Date(scheduledMaintenance.scheduled_for).toLocaleDateString(undefined, {
								month: 'long',
								day: 'numeric',
							})} at ${new Date(scheduledMaintenance.scheduled_for).toLocaleTimeString(undefined, {
								hour: 'numeric',
								minute: '2-digit',
							})}`}
						</ScheduledFor>
					)}
					
					<DetailsLink href={scheduledMaintenance.shortlink} target='_blank' rel='noopener noreferrer'>
						{scheduledMaintenance.status === 'in_progress' ? 'View updates' : 'Learn more'}
						<FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ fontSize: '60%', marginLeft: '0.25rem' }} />
					</DetailsLink>
				</MaintenanceContainer>
			);
		}
	}
	
	
	// Define production domain
	const productionDomain = storybookProductionDomain ?? company.productionDomain;
	
	
	// This should always be defined when this is rendered
	if (!productionDomain) {
		throw new Error('Statuspage used without production domain being defined');
	}
	
	
	// Return JSX
	return (
		<React.Fragment>
			{incidentElements}
			{scheduledMaintenanceElements}
			{!incidentElements.length && allOperational && productionDomain && (
				<SummaryText
					href={`https://status.${productionDomain}`}
					target='_blank'
					rel='noopener noreferrer'
					className='status all-operational'
				>
					<span className='dot'></span> All systems operational <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
				</SummaryText>
			)}
			{!incidentElements.length && !allOperational && productionDomain && (
				<SummaryText
					href={`https://status.${productionDomain}`}
					target='_blank'
					rel='noopener noreferrer'
					className='status some-not-operational'
				>
					<span className='dot'></span> Some systems not operational <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
				</SummaryText>
			)}
		</React.Fragment>
	);
};

export default StatuspageSummary;
